import { getAxiosWithToken } from '../../utils/webApi';
import {
    LOAD_GOALS_SUCCESS,
    LOAD_SELECTED_TAG_STICKERS_SUCCESS,
    SELECT_TAG_SUCCESS,
} from '../actionTypes';
import { GOALS } from '../../utils/constants';

import { setGoalsInLocalStorage } from '../../utils/commonUtils';
import { startLoader, stopLoader } from '../commonActions';

export const loadAllGoals = () => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get('/goal/all');

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_GOALS_SUCCESS, payload: returnedData.data });
        setGoalsInLocalStorage(returnedData.data);
        return returnedData.data;
    }
    return false;
};

export const loadTagStickers = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(`tag/${id}/stickers`, {
        id,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({
            type: LOAD_SELECTED_TAG_STICKERS_SUCCESS,
            payload: returnedData.data,
        });
    }
};

export const loadTag = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(`/tag/get-tag/${id}`, {
        id,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: SELECT_TAG_SUCCESS, payload: returnedData.data });
    }
};

export const loadGoalsFromLocalStorage = () => {
    const goals = JSON.parse(localStorage.getItem(GOALS));
    return { type: LOAD_GOALS_SUCCESS, payload: goals };
};
