import moment from 'moment';
import React, { useMemo } from 'react';
import { TRACKERS_TYPE } from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import formatMeasurement from '../../utils/trackerMeasurement';
import BreastfeedingSticker from '../common/svgComponents/BreastfeedingSticker';
import DiaperHeader from './diapers/DiaperHeader';
import FeedingHeader from './feeding/FeedingHeader';
import MeasurementHeader from './measurement/MeasurementHeader';
import ZeroRecordHeader from './shared/ZeroRecordHeader';

const TrackerHeader = ({ tracker, lastRecord }) => {
  const icon =
    tracker && tracker.image ? (
      <img
        alt="Icon"
        src={navigationMethods.getSystemImageUrl(tracker.image)}
        width={96}
        height={96}
        style={{
          backgroundColor: 'white',
          borderRadius: 8,
          border: '2px solid #87BA3B',
        }}
      />
    ) : (
      <BreastfeedingSticker />
    );

  const HeaderDetails = useMemo(() => {
    if (!lastRecord.type) return <ZeroRecordHeader />;

    const props = {
      beforeText: moment(lastRecord.date).fromNow(),
      quantityText: formatMeasurement(
        lastRecord.quantity,
        tracker.symbol,
        lastRecord.type,
        lastRecord.subType,
        lastRecord.additionalInfo
      ),
    };

    switch (tracker.type.name) {
      case TRACKERS_TYPE.FEEDING:
        return <FeedingHeader {...props} />;
      case TRACKERS_TYPE.CHANGE_DIAPERS:
        return <DiaperHeader subType={tracker.name} {...props} />;
      case TRACKERS_TYPE.MEASUREMENT:
        return <MeasurementHeader {...props} />;
      default:
        return null;
    }
  }, [lastRecord, tracker]);

  return (
    <div className="details">
      {icon}
      <div className="text">{HeaderDetails}</div>
    </div>
  );
};

export default TrackerHeader;
