import * as i18n from 'i18next';
import { defaultServerValidationErrorCallback } from '../utils/commonUtils';
import { RELATIVE_PAGE_PATHS } from '../utils/constants';
import navigationMethods from '../utils/navigationUtils';
import { getAxiosWithToken } from '../utils/webApi';
import {
    LOAD_SPECIALIST,
    LOAD_SPECIALISTS
} from './actionTypes';
import {
    closeModal, showSuccessModal,
    startLoader,
    stopLoader
} from './commonActions';

export const addSpecialist = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post('professional/add', data);

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulAddedSpecialist')
            )
        );
    }
};

export const getSpecialists = () => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get('professional/get-all');

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_SPECIALISTS, payload: returnedData.data });
    }
};

export const getSpecialist = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken(
        null,
        defaultServerValidationErrorCallback
    ).post(`professional/${id}`, { id });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_SPECIALIST, payload: returnedData.data });
    }
};

export const updateSpecialist = (id, data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().put(`professional/${id}`, {
        id,
        ...data,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulUpdatedSpecialist')
            )
        );
    }
};

export const deleteSpecialist = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(`professional/${id}`, {
        data: { id },
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
    }
};

export const shareJournal = (email) => async (dispatch) => {
    dispatch(closeModal());
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'journal/send-email-with-journal-url',
        { email }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch(
            showSuccessModal(
                i18n.t('specialistsScreen:successfulSharedJournal')
            )
        );
    }
};
