import memoize from 'memoize-one';
import * as i18n from 'i18next';
import React from 'react';
import Timer from 'react-compound-timer';
import { Col, Container } from 'reactstrap';
import { addFeeding, checkForNewVersion } from '../actions';
import { getAllTrackers } from '../actions/trackerActions';
import {
  addTrackingRecord,
  getAllTrackingRecords,
  updateTrackingRecord,
} from '../actions/trackingRecordsActions';
import BreastfeedingSticker from './common/svgComponents/BreastfeedingSticker';
import LeftPauseButton from './common/svgComponents/LeftPauseButton';
import LeftPlayButton from './common/svgComponents/LeftPlayButton';
import RightPauseButton from './common/svgComponents/RightPauseButton';
import RightPlayButton from './common/svgComponents/RightPlayButton';
import { Prompt } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Button } from '@mui/material';
import { orderBy } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { TRACKERS_SUB_TYPE } from '../utils/constants';
import { BreastFeedintEditDialog } from './BreastFeedingEditDialog';
import TrackerHistory from './tracker/TrackerHistory';

const sides = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const timerStatuses = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
};

class BreastfeedingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.timer = React.createRef();
  }

  componentDidMount() {
    // this.props.getAllFeedings();
    this.props.getAllTrackers();
    this.props.getAllTrackingRecords();
    this.props.checkForNewVersion();
  }

  state = { timerStatus: timerStatuses.PAUSE, breast: null, seconds: 0 };

  recordToEdit = memoize((records, recordId) => {
    if (!records || !recordId) return null;
    return records.find((x) => x.id + '' === recordId);
  });

  renderLastBreastfeedingDetails = () => {
    const lastBreastfeeding = this.props.records[0];

    if (!lastBreastfeeding) {
      return (
        <div className="text">
          <p>{i18n.t('breastfeedingScreen:lastFeeding')}</p>
          <span>{i18n.t('breastfeedingScreen:notBreastfeedingYet')}</span>
        </div>
      );
    }

    const side = i18n.t(
      `trackingScreen:feeding.${lastBreastfeeding.additionalInfo}`
    );
    const beforeTime = moment.duration(
      moment(new Date()).diff(lastBreastfeeding.date)
    );
    const days = Math.floor(beforeTime.asDays());
    return (
      <div className="text">
        <p>{i18n.t('breastfeedingScreen:lastFeeding')}</p>
        <p className="mb-0">
          <strong className="uppercase">{side} </strong>
          {i18n.t('breastfeedingScreen:breast')}
        </p>
        <span>
          {i18n.t('breastfeedingScreen:breastfeedingTime', {
            hours: beforeTime.hours(),
            minutes: beforeTime.minutes(),
            days: days ? i18n.t('breastfeedingScreen:days', { days }) : '',
          })}
        </span>
      </div>
    );
  };

  renderLastBreastfeeding = () => {
    return (
      <div className="details">
        <BreastfeedingSticker />
        {this.renderLastBreastfeedingDetails()}
      </div>
    );
  };

  renderRightStartButton = (start, pause) => {
    const { breast, timerStatus } = this.state;

    const startPause = () => {
      if (this.getTime() && breast === sides.LEFT) {
        return;
      }

      if (this.state.timerStatus === timerStatuses.PLAY) {
        pause();
      }

      if (this.state.timerStatus === timerStatuses.PAUSE) {
        start();
      }
      this.setState({ breast: sides.RIGHT });
    };

    const isDisabled = this.state.breast === sides.LEFT;
    const button =
      timerStatus === timerStatuses.PLAY && breast === sides.RIGHT ? (
        <RightPauseButton onClick={startPause} />
      ) : (
        <RightPlayButton
          onClick={startPause}
          color={isDisabled ? null : '#87ba3b'}
        />
      );

    return button;
  };

  renderLeftStartButton = (start, pause) => {
    const { breast, timerStatus } = this.state;

    const startPause = () => {
      if (this.getTime() && breast === sides.RIGHT) {
        return;
      }

      if (this.state.timerStatus === timerStatuses.PLAY) {
        pause();
      }

      if (this.state.timerStatus === timerStatuses.PAUSE) {
        start();
      }
      this.setState({ breast: sides.LEFT });
    };

    const isDisabled = this.state.breast === sides.RIGHT;
    const button =
      timerStatus === timerStatuses.PLAY && breast === sides.LEFT ? (
        <LeftPauseButton onClick={startPause} />
      ) : (
        <LeftPlayButton
          onClick={startPause}
          color={isDisabled ? null : '#87ba3b'}
        />
      );

    return button;
  };

  getTime = () => {
    return this.timer.current ? this.timer.current.getTime() : 0;
  };

  format = (digit) => {
    return ('0' + digit).slice(-2);
  };

  renderTimer = () => {
    const isClearButtonEnabled =
      this.state.breast && this.state.timerStatus !== timerStatuses.PLAY;
    const isSaveButtonEnabled =
      this.state.timerStatus === timerStatuses.PAUSE && this.getTime();
    // const clearButtonClass = isClearButtonEnabled
    //   ? 'btn greenBtnOutline uppercase secondary-btn'
    //   : 'btn greyBtnOutline uppercase secondary-btn';
    // const saveButtonClass = isSaveButtonEnabled
    //   ? 'btn greenBtnOutline uppercase secondary-btn'
    //   : 'btn greyBtnOutline uppercase secondary-btn';

    const onClearClick = isClearButtonEnabled
      ? (stop, reset) => {
          reset();
          stop();
          this.setState({
            timerStatus: timerStatuses.PAUSE,
            breast: null,
          });
        }
      : () => {};

    const onSaveClick = (stop, reset) => {
      if (!isSaveButtonEnabled) return;

      const seconds =
        moment.duration(this.getTime()).seconds() +
        moment.duration(this.getTime()).minutes() * 60;

      this.save(
        this.props.trackerId,
        this.state.startTime,
        seconds,
        this.state.breast
      );

      onClearClick(stop, reset);
    };

    const onAdd = () => {
      this.setState({ isAddOpened: true });
    };

    return (
      <>
        <Timer
          initialTime={0}
          startImmediately={false}
          onStart={() => {
            this.setState({
              timerStatus: timerStatuses.PLAY,
              startTime: new Date(),
            });
          }}
          onPause={() => this.setState({ timerStatus: timerStatuses.PAUSE })}
          ref={this.timer}
        >
          {({ start, resume, pause, stop, reset, timerState }) => (
            <React.Fragment>
              <div className="timer">
                <Timer.Minutes formatValue={this.format} />:
                <Timer.Seconds formatValue={this.format} />
              </div>

              <div className="breastfeeding-buttons">
                <div className="w-50">
                  {this.renderLeftStartButton(start, pause)}
                  <div className="p-3 d-flex justify-content-center">
                    <Button
                      color="error"
                      disabled={!isClearButtonEnabled}
                      variant="contained"
                      onClick={() => {
                        onClearClick(stop, reset);
                      }}
                    >
                      {i18n.t('breastfeedingScreen:buttons.clear')}
                    </Button>
                  </div>
                </div>
                <div className="w-50">
                  {this.renderRightStartButton(start, pause)}
                  <div className="p-3 d-flex justify-content-center">
                    <Button
                      disabled={!isSaveButtonEnabled}
                      variant="contained"
                      onClick={() => {
                        onSaveClick(stop, reset);
                      }}
                    >
                      {i18n.t('breastfeedingScreen:buttons.save')}
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Timer>
        <div className="pb-3 d-flex justify-content-center">
          <Button
            type="submit"
            variant="outlined"
            onClick={onAdd}
            disabled={
              this.state.isAddOpened ||
              this.state.timerStatus === timerStatuses.PLAY
            }
          >
            {i18n.t('breastfeedingScreen:buttons.add')}
          </Button>
        </div>
      </>
    );
  };

  save = (trackerId, startTime, duration, breast) => {
    this.props.addTrackingRecord(trackerId, startTime, duration, breast);
  };

  update = (id, startTime, duration, breast) => {
    this.props.updateTrackingRecord(id, startTime, duration, breast);
  };

  handleSave = (data) => {
    const { startTime, duration, breast } = data;

    if (this.props.match.params.recordId) {
      this.update(
        this.props.match.params.recordId,
        startTime,
        duration,
        breast
      );
      // this.setState({ recordToEdit: null });
      this.props.history.replace('/breastfeeding');
      return;
    }

    this.save(this.props.trackerId, startTime, duration, breast);
  };

  calculateTheDSTOffset = (time) => {
    if (moment().isDST()) {
      return !moment(time).isDST()
        ? moment(time).add(-1, 'hours')
        : moment(time);
    }
    return moment(time).isDST() ? moment(time).add(1, 'hours') : moment(time);
  };

  onAddClose = () => {
    this.setState({ isAddOpened: false });
    this.props.history.replace('/breastfeeding');
  };

  handleEdit = (id) => {
    this.props.history.push('/breastfeeding/' + id);
    // const record = this.props.records.find((x) => x.id === id);
    // if (!record) return;
    // this.setState({
    //   isAddOpened: true,
    //   recordToEdit: record,
    // });
  };

  render() {
    const recordToEdit = this.recordToEdit(
      this.props.records,
      this.props.match.params.recordId
    );
    const isAddOpened = !!recordToEdit || this.state.isAddOpened;

    return (
      <Container className="noPadding ml-3 mr-3 limited-width-journal mb-5">
        <Prompt
          when={this.state.timerStatus === timerStatuses.PLAY}
          message={i18n.t('breastfeedingScreen:prompt')}
        />

        <Col xs={12} className="centred noPadding journal">
          <div className="flexContainerColumn flexContainerCentered fix-padding breastfeeding">
            {this.renderLastBreastfeeding()}
            {this.renderTimer()}
            <TrackerHistory
              historyRecords={this.props.records}
              isBreastFeeding
              onEdit={this.handleEdit}
              disableEdit={this.state.timerStatus === timerStatuses.PLAY}
            />
          </div>
        </Col>

        {isAddOpened && (
          <BreastFeedintEditDialog
            startTime={recordToEdit ? new Date(recordToEdit?.date) : null}
            endTime={
              recordToEdit
                ? moment(new Date(recordToEdit?.date))
                    .add(recordToEdit?.quantity, 'seconds')
                    .toDate()
                : null
            }
            isOpen={isAddOpened}
            onClose={this.onAddClose}
            onSave={this.handleSave}
            isEdit={!!recordToEdit}
          />
        )}
      </Container>
    );
  }
}
const trackerIdSelector = (state) => {
  const tracker = (state.trackers || []).find(
    (x) => x.name === TRACKERS_SUB_TYPE.BREAST_FEEDING
  );
  return tracker ? tracker.id : -1;
};
const mapStateToProps = (state) => {
  return {
    // breastfeeding: state.breastfeeding,
    trackerId: trackerIdSelector(state),
    records: orderBy(
      state.trackingRecords.filter(
        (x) => x.subTypeId === trackerIdSelector(state)
      ),
      (x) => new Date(x.date),
      ['desc']
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    addFeeding,
    addTrackingRecord,
    updateTrackingRecord,
    // getAllFeedings,
    getAllTrackers,
    getAllTrackingRecords,
    checkForNewVersion,
  })(BreastfeedingScreen)
);
