import moment from 'moment';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { TimerValue, useTimer } from 'react-compound-timer';
import { useTimerState } from '../hooks/useTimerState';
import { TIMER_STATE, TimerStateType } from '../models/timerStatuses';

interface TrackingTimerContextProps {
  start: (value: string) => void;
  stop: () => void;
  reset: () => void;
  setTracker: (name: string) => void;
  getDuration: () => number;
  tracker: string;
  value?: TimerValue;
  state: TimerStateType;
  startTime?: Date;
  additionalInfo: string;
  isWorking: boolean;
}

const defaultValues: TrackingTimerContextProps = {
  start(value) {},
  stop() {},
  reset() {},
  setTracker(name) {},
  tracker: '',
  state: TIMER_STATE.INIT,
  additionalInfo: '',
  isWorking: false,
  getDuration: () => 0,
};

export const TrackingTimerContext =
  createContext<TrackingTimerContextProps>(defaultValues);

export const TrackingTimerProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: timerState,
    setAddInfo,
    setStartTime,
    setTimerState,
    setTracker,
    clearStartTime,
  } = useTimerState();

  const initialTime = useMemo(() => {
    if (timerState?.startTime) {
      return moment().diff(moment(timerState.startTime), 's') * 1000;
    }
    return 0;
  }, [timerState]);

  const startImmediately = useMemo(() => {
    return initialTime > 0 && timerState?.state === TIMER_STATE.PLAY;
  }, [initialTime, timerState]);

  const handleSetTimerStatus = useCallback(
    (status: TimerStateType) => {
      setTimerState(status);
    },
    [setTimerState]
  );

  const handleStart = () => {
    console.log('start');
    handleSetTimerStatus(TIMER_STATE.PLAY);
    setStartTime(new Date());
  };
  const handleStop = () => {
    console.log('stop');
    // do not stop the timer, this is called when the timer is destroyed
    // handleSetTimerStatus(TIMER_STATUSES.STOP);
  };
  const handleReset = () => {
    handleSetTimerStatus(TIMER_STATE.STOP);
    clearStartTime();
  };
  const handlePause = () => {
    handleSetTimerStatus(TIMER_STATE.PAUSE);
  };
  const handleResume = () => {
    handleSetTimerStatus(TIMER_STATE.PLAY);
  };

  const timer = useTimer({
    initialTime,
    startImmediately,
    onStart: handleStart,
    onStop: handleStop,
    onPause: handlePause,
    onReset: handleReset,
    onResume: handleResume,
  });

  const start = useCallback(
    (value: string) => {
      setAddInfo(value);
      timer.controls.start();
    },
    [setAddInfo, timer.controls]
  );

  const stop = useCallback(() => {
    timer.controls.pause();
  }, [timer.controls]);

  const reset = useCallback(() => {
    timer.controls.reset();
    timer.controls.setTime(0);
  }, [timer.controls]);

  const getDuration = () => {
    const duration =
      moment.duration(timer.controls.getTime()).seconds() +
      moment.duration(timer.controls.getTime()).minutes() * 60;
    return duration;
  };

  return (
    <TrackingTimerContext.Provider
      value={{
        start,
        stop,
        reset,
        setTracker,
        tracker: timerState?.tracker || '',
        state: timerState?.state || TIMER_STATE.INIT,
        value: timer.value,
        additionalInfo: timerState?.additionalInfo || '',
        isWorking: timerState?.state === TIMER_STATE.PLAY,
        getDuration,
        startTime: timerState?.startTime,
      }}
    >
      {children}
    </TrackingTimerContext.Provider>
  );
};

export const useTrackingTimer = (trackerType?: string) => {
  const {
    setTracker,
    isWorking,
    tracker,
    start,
    stop,
    reset,
    value,
    state,
    getDuration,
    additionalInfo,
    startTime,
  } = useContext(TrackingTimerContext);
  if (!isWorking && trackerType && tracker !== trackerType) {
    setTracker(trackerType);
  }

  const isTrackerSelected = tracker === trackerType;
  return {
    isTrackerSelected,
    isWorking,
    start,
    stop,
    reset,
    timerState: state,
    value,
    getDuration,
    additionalInfo,
    tracker,
    startTime,
  };
};
