import * as i18n from 'i18next';
import { getFormValues, reset } from 'redux-form';
import { showErrorModal } from '..';
import appUtils from '../../utils/appUtils';
import {
    defaultServerValidationErrorCallback,
    isShareButtonDisabled
} from '../../utils/commonUtils';
import {
    DOWNLOADED_JOURNAL_FILE_NAME, FORMS,
    RELATIVE_PAGE_PATHS, REQUEST_RESPONSE_CODE
} from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';
import { getAxiosWithToken } from '../../utils/webApi';
import {
    DELETE_ADDED_STICKER_SUCCESS, EXPIRED_STICKERS_EXIST,
    GET_EXPIRED_STICKERS, GET_USER_DETAILS, LOAD_ADDED_STICKER_SUCCESS, LOAD_JOURNAL_SUCCESS, SELECT_STICKER_SUCCESS
} from '../actionTypes';
import {
    showShareModal,
    showSuccessModal,
    startLoader,
    stopLoader
} from '../commonActions';

export const addStickerToJournal =
    (stickerTemplateId, entered, userText) => async (dispatch) => {
        entered = appUtils.convertDateToBackendFormat(entered);

        dispatch(startLoader());

        const response = await getAxiosWithToken().post('journal/add-sticker', {
            stickerTemplateId,
            entered,
            userText,
        });

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            const isShareAvailable = !isShareButtonDisabled(returnedData.data);
            const message = isShareAvailable
                ? i18n.t('addStickerScreen:successfulAddedStickerAllowingShare')
                : i18n.t('addStickerScreen:successfulAddedSticker');
            dispatch({
                type: LOAD_ADDED_STICKER_SUCCESS,
                payload: returnedData.data,
            });
            if (isShareAvailable) {
                dispatch(showShareModal(message));
            } else {
                dispatch(showSuccessModal(message));
            }
            // Another check if there are expired stickers not added to
            // the journal needs to be done every time a sticker
            // is added or deleted
            dispatch(checkIfExpiredStickersExist());

            navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
        }
    };

export const addStickerWithPhotoToJournal =
    (stickerTemplateId, entered, userText) => async (dispatch, getState) => {
        let formData = new FormData();
        const formsState = getFormValues(FORMS.STICKER_FORM)(getState());
        const photo = formsState.uploadPhoto[0];

        entered = appUtils.convertDateToBackendFormat(entered);

        formData.append(
            'sticker-data',
            JSON.stringify({ stickerTemplateId, entered, userText })
        );
        formData.append('photo-file', photo);

        dispatch(startLoader());

        const response = await getAxiosWithToken(true).post(
            'journal/add-sticker-with-photo',
            formData
        );

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            const isShareAvailable = !isShareButtonDisabled(returnedData.data);
            const message = isShareAvailable
                ? i18n.t('addStickerScreen:successfulAddedStickerAllowingShare')
                : i18n.t('addStickerScreen:successfulAddedSticker');
            dispatch({
                type: LOAD_ADDED_STICKER_SUCCESS,
                payload: returnedData.data,
            });
            if (isShareAvailable) {
                dispatch(showShareModal(message));
            } else {
                dispatch(showSuccessModal(message));
            }
            // Another check if there are expired stickers not added to
            // the journal needs to be done every time a sticker
            // is added or deleted
            dispatch(checkIfExpiredStickersExist());

            navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
        } else if (returnedData.code === 406) {
            dispatch(reset(FORMS.STICKER_FORM));
        }
    };

export const addStickerImage = (event, id) => async (dispatch) => {
    event.preventDefault();

    let formData = new FormData();

    let stickerId = { id };
    let file = event.target.files[0];

    formData.append('sticker-data', JSON.stringify(stickerId));
    formData.append('photo-file', file);

    dispatch(startLoader());

    const response = await getAxiosWithToken(true).post(
        `/journal/upload-sticker-photo/${id}`,
        formData
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({
            type: LOAD_ADDED_STICKER_SUCCESS,
            payload: returnedData.data,
        });
    }
};

export const getJournal = (tagId) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post('journal/list-stickers', {
        tagId,
    });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: LOAD_JOURNAL_SUCCESS, payload: returnedData.data });
    }
};

export const checkIfExpiredStickersExist = () => async (dispatch) => {
    const response = await getAxiosWithToken().get(
        'journal/expired-stickers-exist'
    );

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: EXPIRED_STICKERS_EXIST, payload: returnedData.data });
    }
};

export const loadSticker = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        `/sticker/get-sticker/${id}`,
        { id }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: SELECT_STICKER_SUCCESS, payload: returnedData.data });
    }
};

export const loadExpiredStickers = (tagId) => async (dispatch) => {
    var requestBody = {};
    if (tagId) {
        requestBody = { tagId };
    }

    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'journal/list-expired-stickers',
        requestBody
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: GET_EXPIRED_STICKERS, payload: returnedData.data });
    }
};

export const getAddedSticker = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken(
        null,
        defaultServerValidationErrorCallback
    ).post(`journal/get-sticker/${id}`, { id });

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({
            type: LOAD_ADDED_STICKER_SUCCESS,
            payload: returnedData.data,
        });
    }
};

export const getReadOnlyAddedSticker =
    (encryptedUserId, id) => async (dispatch) => {
        dispatch(startLoader());

        const response = await getAxiosWithToken(
            null,
            defaultServerValidationErrorCallback
        ).post(`journal/get-read-only-sticker/${id}`, { encryptedUserId, id });

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            dispatch({
                type: LOAD_ADDED_STICKER_SUCCESS,
                payload: returnedData.data,
            });
        }
    };

export const getReadOnlyJournal =
    (encryptedUserId, tagId) => async (dispatch) => {
        dispatch(startLoader());

        const response = await getAxiosWithToken().post(
            'journal/list-read-only-stickers',
            { encryptedUserId, tagId }
        );

        dispatch(stopLoader());

        const returnedData = response.data;
        if (returnedData.success) {
            dispatch({
                type: LOAD_JOURNAL_SUCCESS,
                payload: returnedData.data,
            });
        }
    };

export const getReadOnlyJournalUser = (encryptedUserId) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'journal/get-read-only-journal-info',
        { encryptedUserId }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch({ type: GET_USER_DETAILS, payload: returnedData.data });
    }
};

export const requestJournalDownload = () => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().get(
        'journal/generate-for-download'
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        const message = i18n.t('requestJournalDownloadScreen:successMessage');
        dispatch(showSuccessModal(message));
    }
};

export const downloadJournal = (dataText, tokenText) => async (dispatch) => {
    // There are a few other options for encoding URI-s but
    // encodedURIComponent is the right one when we want
    // to encode the special characters in query string
    // param values, as in our case here
    const encodedDataText = encodeURIComponent(dataText);
    const encodedTokenText = encodeURIComponent(tokenText);

    dispatch(startLoader());

    // Get the journal compressed file as a blob from backend
    await getAxiosWithToken()
        .get(
            `journal/download?data=${encodedDataText}&token=${encodedTokenText}`,
            { responseType: 'blob' }
        )
        .then((response) => {
            console.log(
                'Successful download! Starting to process the Blob object'
            );

            const blob = new Blob([response.data]);

            // Create blob link to download
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', DOWNLOADED_JOURNAL_FILE_NAME);

            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            const message = i18n.t('downloadJournalScreen:successMessage');
            dispatch(showSuccessModal(message, true));
        })
        .catch((error) => {
            if (
                error.response.status !==
                REQUEST_RESPONSE_CODE.NOT_ACCEPTABLE_406
            ) {
                // Do nothing
                return;
            }

            const errorMessage = i18n.t('downloadJournalScreen:errorMessage');
            dispatch(showErrorModal(errorMessage));
        });

    // navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);

    dispatch(stopLoader());
};

export const updateAddedSticker = (id, data) => async (dispatch) => {
    data.entered = appUtils.convertDateToBackendFormat(data.entered);

    dispatch(startLoader());

    const response = await getAxiosWithToken().put(
        `journal/update-sticker/${id}`,
        { id, ...data }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        const isShareAvailable = !isShareButtonDisabled(returnedData.data);
        const message = isShareAvailable
            ? i18n.t('addStickerScreen:successfullyUpdatedStickerAllowingShare')
            : i18n.t('addStickerScreen:successfullyUpdatedSticker');
        dispatch({
            type: LOAD_ADDED_STICKER_SUCCESS,
            payload: returnedData.data,
        });
        if (isShareAvailable) {
            dispatch(showShareModal(message));
        } else {
            dispatch(showSuccessModal(message));
        }

        navigationUtils.navigate(RELATIVE_PAGE_PATHS.JOURNAL);
    }
};

export const deleteAddedSticker = (id) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(
        `journal/delete-sticker/${id}`,
        { data: { id } }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        dispatch(checkIfExpiredStickersExist());
        dispatch({ type: DELETE_ADDED_STICKER_SUCCESS, payload: id });
    }
};

export const deleteStickerPhoto = (stickerId, callback) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(
        `journal/delete-sticker-photo/${stickerId}`,
        { data: { stickerId } }
    );

    dispatch(stopLoader());

    const returnedData = response.data;
    if (returnedData.success) {
        callback();
    }
};
