import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as i18n from 'i18next';
import { Container, Col, Button } from 'reactstrap';

import DownloadJournal from '../common/svgComponents/DownloadJournal';
import { checkForNewVersion, requestJournalDownload } from '../../actions';

class RequestJournalDownloadScreen extends Component {
    componentDidMount() {
        this.props.checkForNewVersion();
    }

    render = () => {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <div className="flexContainerColumn flexContainerCentered fix-padding">
                        <p style={{ marginTop: 30 }} />
                        <div
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerCentered"
                        >
                            <DownloadJournal width={166} height={168} />
                        </div>
                        <div className="primaryHeader">
                            <span className="uppercase">
                                {i18n.t('requestJournalDownloadScreen:for')}{' '}
                                {this.props.user.childName}
                            </span>
                        </div>
                        <div className="flexContainerColumn flexContainerCentered description">
                            <div>
                                {i18n.t(
                                    'requestJournalDownloadScreen:description'
                                )}
                            </div>
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred"
                            style={{ margin: '40px 0px 12px 0px' }}
                        >
                            <Button
                                onClick={this.props.requestJournalDownload}
                                type="submit"
                                className="greenBtn header next uppercase"
                            >
                                {i18n.t(
                                    'requestJournalDownloadScreen:submitButton'
                                )}
                            </Button>
                        </Col>
                    </div>
                </Col>
            </Container>
        );
    };

    onSubmit = (formValues) =>
        this.props.requestPasswordReset(formValues.email);
}

const mapStateToProps = (state) => {
    return {
        user: state.authentication.userData,
    };
};

export default connect(mapStateToProps, {
    requestJournalDownload,
    checkForNewVersion,
})(RequestJournalDownloadScreen);
