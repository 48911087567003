import { Button, Typography } from '@mui/material';
import * as i18n from 'i18next';
import { useCallback, useState } from 'react';
import { useTrackingTimer } from '../../../context/TrackingTimerProvider';
import {
  TIME_OF_THE_DAY,
  timeOfTheDayType,
} from '../../../models/timeOfTheDay';
import { TIMER_STATE } from '../../../models/timerStatuses';
import { TRACKERS_SUB_TYPE, TRACKERS_TYPE } from '../../../utils/constants';
import formatMeasurement from '../../../utils/trackerMeasurement';
import SimpleAlert from '../shared/SimpleAlert';
import { TimerVisualizer } from '../shared/TimerVisualizer';
import { SleepDayButton } from './SleepDayButton';
import { SleepNightButton } from './SleepNightButton';

interface SleepTimerProps {
  onSave?: (data: {
    startTime: Date;
    duration: number;
    timeOfTheDay: string;
  }) => void;
}

export const SleepTimer = ({ onSave }: SleepTimerProps) => {
  const {
    start,
    stop,
    reset,
    isWorking,
    timerState: timerStatus,
    getDuration,
    value,
    additionalInfo,
    startTime,
    isTrackerSelected,
    tracker,
  } = useTrackingTimer(TRACKERS_SUB_TYPE.SLEEP);

  const [timeOfTheDay, setTimeOfTheDay] = useState<
    timeOfTheDayType | undefined
  >();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const isClearButtonEnabled = isTrackerSelected && !isWorking && getDuration();
  const isSaveButtonEnabled =
    isTrackerSelected && timerStatus !== TIMER_STATE.PLAY && getDuration();
  const startStopHanlder = useCallback(
    (tod: timeOfTheDayType) => {
      return () => {
        if (timerStatus === TIMER_STATE.PLAY && additionalInfo !== tod) {
          return;
        }
        if (isWorking) {
          stop();
        }

        if (!isWorking) {
          if (isClearButtonEnabled) {
            setShowConfirmation(true);
          } else {
            start(tod);
          }
        }
        setTimeOfTheDay(tod);
      };
    },
    [
      additionalInfo,
      isWorking,
      isClearButtonEnabled,
      setShowConfirmation,
      start,
      stop,
      setTimeOfTheDay,
      timerStatus,
    ]
  );

  const clear = () => {
    reset();
  };

  const handleClearButtonClick = () => {
    if (isClearButtonEnabled) {
      clear();
    }
  };

  const handleConfirm = (confirm: boolean) => {
    if (confirm) {
      clear();
      start(timeOfTheDay || TIME_OF_THE_DAY.DAY);
    }
    setShowConfirmation(false);
  };

  const handleSave = () => {
    if (startTime && additionalInfo) {
      onSave?.({
        startTime,
        duration: getDuration(),
        timeOfTheDay: additionalInfo,
      });
      clear();
    }
  };

  return (
    <>
      {isTrackerSelected ? (
        <TimerVisualizer value={value} />
      ) : (
        <Typography>{`Тракерът за ${i18n.t(
          `trackingScreen:trackers.${tracker} е активен в момента!`
        )}`}</Typography>
      )}
      <div className="breastfeeding-buttons">
        <div className="w-50">
          <SleepDayButton
            disabled={
              !isTrackerSelected ||
              (additionalInfo === TIME_OF_THE_DAY.NIGHT && isWorking)
            }
            isActive={additionalInfo === TIME_OF_THE_DAY.DAY && isWorking}
            onClick={startStopHanlder(TIME_OF_THE_DAY.DAY)}
          />
          <div className="p-3 d-flex justify-content-center">
            <Button
              color="error"
              disabled={!isClearButtonEnabled}
              variant="contained"
              onClick={handleClearButtonClick}
            >
              {i18n.t('sleepTrackerScreen:buttons.clear')}
            </Button>
          </div>
        </div>
        <div className="w-50">
          <SleepNightButton
            disabled={
              !isTrackerSelected ||
              (additionalInfo === TIME_OF_THE_DAY.DAY && isWorking)
            }
            isActive={additionalInfo === TIME_OF_THE_DAY.NIGHT && isWorking}
            onClick={startStopHanlder(TIME_OF_THE_DAY.NIGHT)}
          />
          <div className="p-3 d-flex justify-content-center">
            <Button
              disabled={!isSaveButtonEnabled}
              variant="contained"
              onClick={handleSave}
            >
              {i18n.t('sleepTrackerScreen:buttons.save')}
            </Button>
          </div>
        </div>
      </div>
      <SimpleAlert
        title={'Сигурни ли сте?'}
        message={`Ако стартирате ново засичане, ще загубите текущото засичане на "${formatMeasurement(
          getDuration(),
          's',
          TRACKERS_TYPE.TRACKING,
          TRACKERS_SUB_TYPE.SLEEP,
          timeOfTheDay
        )}"? Искате ли да продължите?`}
        open={showConfirmation}
        onClose={handleConfirm}
      />
    </>
  );
};
