import {
  CLEAR_REDUCERS,
  LOAD_SPECIALIST,
  LOAD_SPECIALISTS,
} from '../actions/actionTypes';

const initialState = { specialists: [], specialist: {} };

const specialistsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOAD_SPECIALISTS:
      state.specialists = [...action.payload];
      return { ...state };
    case LOAD_SPECIALIST:
      state.specialist = { ...action.payload };
      return { ...state };
    case CLEAR_REDUCERS:
      state.specialists = [...initialState.specialists];
      state.specialist = { ...initialState.specialist };
      state = { ...state };
      return state;
    default:
      return state;
  }
};

export default specialistsReducer;
