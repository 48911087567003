import { FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useField } from 'react-final-form';

export const TrackerDateTime = ({ name }) => {
  const { input, meta } = useField(name);
  const handleChange = (field) => (event) => {
    if (field === 'date') {
      input.onChange(
        moment(
          `${event.target.value} ${moment(input.value).format('HH:mm')}`
        ).toISOString()
      );
    } else if (field === 'time') {
      input.onChange(
        moment(
          `${moment(input.value).format('YYYY-MM-DD')} ${event.target.value}`
        ).toISOString()
      );
    }
  };
  return (
    <FormControl fullWidth>
      <Stack direction="row" spacing={2} width={'100%'}>
        <TextField
          fullWidth
          id="date"
          label="Дата"
          type="date"
          value={moment(input.value).format('YYYY-MM-DD')}
          onChange={handleChange('date')}
          onBlur={input.onBlur}
          sx={{ minWidth: '175px' }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ max: moment().format('YYYY-MM-DD') }}
          error={meta.error && meta.touched}
        />
        <TextField
          fullWidth
          id="time"
          label="Час"
          type="time"
          value={moment(input.value).format('HH:mm')}
          onChange={handleChange('time')}
          onBlur={input.onBlur}
          // sx={{ width: 150 }}
          InputLabelProps={{
            shrink: true,
          }}
          error={meta.error && meta.touched}
        />
      </Stack>
      <FormHelperText error={meta.error && meta.touched}>
        {meta.error && meta.touched && meta.error}
      </FormHelperText>
    </FormControl>
  );
};
