import { FC, FunctionComponent, SVGProps, createElement } from 'react';

interface StartStopButtonProps {
  startButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabledButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  stopButton: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}
export const StartStopButton: FC<StartStopButtonProps> = ({
  stopButton,
  startButton,
  disabledButton,
  disabled,
  onClick,
  isActive,
}) => {
  if (isActive) {
    return createElement(stopButton, { onClick });
  }
  if (disabled) {
    return createElement(disabledButton);
  }
  return createElement(startButton, { onClick });
};
