import React from 'react';

const MainMenu = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 22 19"
        >
            <g
                id="Футър_икона_-_хамбургер_меню"
                data-name="Футър икона - хамбургер меню"
                transform="translate(-20.786 -20.355)"
            >
                <rect
                    id="Rectangle_731"
                    data-name="Rectangle 731"
                    width="22"
                    height="3"
                    rx="1.5"
                    transform="translate(20.786 20.355)"
                    fill="#87ba3b"
                />
                <rect
                    id="Rectangle_732"
                    data-name="Rectangle 732"
                    width="22"
                    height="3"
                    rx="1.5"
                    transform="translate(20.786 28.355)"
                    fill="#87ba3b"
                />
                <rect
                    id="Rectangle_733"
                    data-name="Rectangle 733"
                    width="22"
                    height="3"
                    rx="1.5"
                    transform="translate(20.786 36.355)"
                    fill="#87ba3b"
                />
            </g>
        </svg>
    );
};

export default MainMenu;
