import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { COLOR_SCHEME } from '../../utils/constants';

class Loader extends React.Component {
    render() {
        if (!this.props.loader) {
            return <Fragment />;
        }

        return (
            <div className="spinner-modal">
                <ReactLoading
                    type="spinningBubbles"
                    color={COLOR_SCHEME.GREEN}
                    height="auto"
                    width={150}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { loader: state.loader };
};

export default connect(mapStateToProps)(Loader);
