import { startLoader, stopLoader } from './commonActions';
import { getAxiosWithToken } from '../utils/webApi';
import { LOAD_BREASTFEEDING } from './actionTypes';

export const addFeeding = (leftSide, durationInSeconds) => async (dispatch) => {
    dispatch(startLoader());

    await getAxiosWithToken().post('feeding/add', {
        durationInSeconds,
        leftSide,
        utcStartTime: new Date(),
    });

    dispatch(getAllFeedings());
};

export const getAllFeedings = () => async (dispatch) => {
    dispatch(startLoader());

    const utcTimeOffset = new Date().getTimezoneOffset() / 60;
    const response = await getAxiosWithToken().post('feeding/get-all', {
        utcTimeOffset,
    });

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        dispatch({ type: LOAD_BREASTFEEDING, payload: returnedData.data });
    }
};
