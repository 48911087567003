import * as i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { showInfoModal } from '../../actions';
import {
    getChildMonths,
    isNoStickers
} from '../../utils/commonUtils';
import { COLOR_SCHEME, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationUtils from '../../utils/navigationUtils';
import ExpiredBorder from '../common/svgComponents/GoalsAndTagsIcons/ExpiredBorder';
import Lock from '../common/svgComponents/GoalsAndTagsIcons/Lock';
import MonthNumber from '../common/svgComponents/GoalsAndTagsIcons/MonthNumber';

class NotAddedStickersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChildBorn: false,
            childMonths: null,
        };
    }

    componentDidMount() {
        this.checkIfChildIsBorn();
    }

    checkIfChildIsBorn() {
        if (this.props.user) {
            let childDateOfBirth = moment(this.props.user.childDateOfBirth);
            let isDateBeforeToday = childDateOfBirth.isBefore(moment(), 'day');
            this.checkChildMonths(isDateBeforeToday);
        }
    }

    checkChildMonths(isDateBeforeToday) {
        const childMonths = getChildMonths(
            this.props.user.childDateOfBirth,
            isDateBeforeToday
        );

        this.setState({
            ...this.state,
            isChildBorn: isDateBeforeToday,
            childMonths: childMonths,
        });
    }

    addSticker = (sticker) => {
        navigationUtils.navigate(RELATIVE_PAGE_PATHS.ADD_STICKER + sticker.id);
    };

    addPaidSticker = () => {
        const paidStickerMsg = i18n.t(
            'notAddedStickersListScreen:paidStickerMsg'
        );
        this.props.showInfoModal(paidStickerMsg);
    };

    renderStickerValidation(sticker) {
        if (sticker.isPaid) {
            return <Lock style={{ position: 'absolute', borderRadius: 12 }} />;
        } else if (sticker.isExpired) {
            return (
                <ExpiredBorder
                    style={{ position: 'absolute', borderRadius: 12 }}
                />
            );
        }
    }

    loadStickers(stickerGroup, monthBackgroundColor) {
        const sectionClass =
            !this.props.oneColorMonths && monthBackgroundColor !== 'transparent'
                ? 'stickerGroupContainer expired-stickers mt-3 mb-3'
                : 'stickerGroupContainer expired-stickers';

        return (
            <Col
                lg={12}
                className={sectionClass}
                style={{
                    background: !this.props.oneColorMonths
                        ? monthBackgroundColor
                        : 'transparent',
                    width: '100%',
                }}
            >
                {stickerGroup.map((sticker, index) => {
                    let stickersContainerClass =
                        sticker.isPaid || sticker.isExpired
                            ? 'stickersContainer noBorder'
                            : 'stickersContainer';

                    return (
                        <div
                            key={index}
                            className={
                                sticker.isExpired && !sticker.isPaid
                                    ? 'sticker yellow-sticker'
                                    : 'sticker'
                            }
                        >
                            <div
                                className={stickersContainerClass}
                                onClick={() => {
                                    sticker.isPaid
                                        ? this.addPaidSticker()
                                        : this.addSticker(sticker);
                                }}
                            >
                                <img
                                    alt="sticker"
                                    src={navigationUtils.getSystemImageUrl(
                                        sticker.image
                                    )}
                                />
                                {this.renderStickerValidation(sticker)}
                            </div>
                        </div>
                    );
                })}
            </Col>
        );
    }

    render() {
        const { stickers } = this.props;
        const { borderColor, backgroundColor } = this.props;
        const { childMonths } = this.state;

        if (isNoStickers(stickers)) {
            return (
                <p
                    className="noContent newLineText"
                    style={{ color: borderColor }}
                >
                    {i18n.t('selectedTagScreen:noStickers')}
                </p>
            );
        }

        return stickers.map((stickerGroup, index) => {
            let monthColor = this.props.oneColorMonths
                ? COLOR_SCHEME.GREEN
                : childMonths === index
                ? borderColor
                : COLOR_SCHEME.LIGHT_GRAY;
            let monthBackgroundColor =
                childMonths === index ? backgroundColor : 'transparent';
            if (stickerGroup.length > 0) {
                const monthClass =
                    index === 0
                        ? 'flexColumnCentered noMargin noPadding mt-m'
                        : 'flexColumnCentered noPadding mt-3';
                return (
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        key={index}
                        className={monthClass}
                    >
                        <MonthNumber
                            number={index}
                            fill={monthColor}
                            className="not-added-sticker-mount-number"
                        />
                        {this.loadStickers(stickerGroup, monthBackgroundColor)}
                    </Col>
                );
            }
            return null;
        });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authentication.userData,
    };
};

export default connect(mapStateToProps, { showInfoModal })(
    NotAddedStickersList
);
