import React, { Fragment } from 'react';
import * as i18n from 'i18next';
import { Col, Container } from 'reactstrap';
import JournalHeader from './JournalHeader';
import TagFilter from './TagFilter';
import JournalItems from './JournalItems';
import { connect } from 'react-redux';
import navigationUtils from '../../utils/navigationUtils';
import {
    getReadOnlyJournal,
    loadAllGoals,
    getReadOnlyJournalUser,
    isTokenInLocalStorage,
    showErrorModal,
} from '../../actions';
import { getFormValues } from 'redux-form';
import { FORMS, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import LocalNavigationComponent from '../LocalNavigationComponent';

class JournalReadOnlyScreen extends React.Component {
    state = { activeItemMenu: 0 };

    componentDidMount() {
        if (isTokenInLocalStorage()) {
            const message = i18n.t(
                'journalScreen:logOutToAccessSharedJournalMessage'
            );
            this.props.showErrorModal(message);

            navigationUtils.navigate(RELATIVE_PAGE_PATHS.HOME);
            return;
        }

        const tag = this.props.formValues ? this.props.formValues.tag : {};
        const params = new URLSearchParams(this.props.location.search);

        if (tag.id !== undefined) {
            this.props.getReadOnlyJournal(params.get('user-id'), tag.id);
        } else {
            this.props.getReadOnlyJournalUser(params.get('user-id'));
            this.props.loadAllGoals().then((res) => {
                if (res) {
                    const worriesId = this.getWorriesId(res);
                    this.props.getReadOnlyJournal(
                        params.get('user-id'),
                        worriesId
                    );
                }
            });
        }
    }

    render() {
        if (isTokenInLocalStorage() || !this.props.goals) {
            return <Fragment />;
        }

        const params = new URLSearchParams(this.props.location.search);

        return (
            <div className="flexContainerCentered centred noPadding ml-3 mr-3">
                <Container className="noPadding ml-3 mr-3 limited-width-journal mb-5">
                    <Col xs={12} className="centred noPadding journal">
                        <LocalNavigationComponent />
                        <div
                            className="flexContainerColumn flexContainerCentered profile-form fix-padding"
                            onClick={this.onCloseItemMenu}
                        >
                            <JournalHeader />
                            <TagFilter
                                onTagSelectedCallback={(tagId) =>
                                    this.onTagSelected(tagId)
                                }
                                initialValues={{
                                    name: i18n.t('journalScreen:worries'),
                                    id: this.getWorriesId(this.props.goals),
                                }}
                            />
                            <JournalItems
                                onDotsClick={this.onDotsClick}
                                activeItemMenu={this.state.activeItemMenu}
                                userId={params.get('user-id')}
                            />
                        </div>
                    </Col>
                </Container>
            </div>
        );
    }

    getWorriesId = (goals) => {
        let worriesId = undefined;
        goals.forEach((goal) => {
            goal.tags.forEach((tag) => {
                if (tag.name === i18n.t('journalScreen:worries')) {
                    worriesId = tag.id;
                }
            });
        });
        return worriesId;
    };

    onDotsClick = (item) => {
        if (this.props.activeItemMenu === item.id) {
            this.setState({ activeItemMenu: 0 });
        } else {
            this.setState({ activeItemMenu: item.id });
        }
    };

    onTagSelected = (tagId) => {
        const params = new URLSearchParams(this.props.location.search);
        this.props.getReadOnlyJournal(params.get('user-id'), tagId);
    };

    onCloseItemMenu = () => {
        if (this.state.activeItemMenu !== 0) {
            this.setState({ activeItemMenu: 0 });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        selectedTag: state.selectedTag,
        user: state.authentication.userData,
        journal: state.journal,
        goals: state.goals,
        formValues: getFormValues(FORMS.TAG_FORM)(state),
    };
};

export default connect(mapStateToProps, {
    getReadOnlyJournal,
    loadAllGoals,
    getReadOnlyJournalUser,
    showErrorModal,
})(JournalReadOnlyScreen);
