import { Box, Typography } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import { Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { deleteTrackingRecord } from '../../actions/trackingRecordsActions';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import formatMeasurement from '../../utils/trackerMeasurement';
import DateDivider from './DateDivider';
import TrackerHistoryItem from './TrackerHistoryItem';

// TODO Same code like in TrackerHistory
const TrackerTotalHistory = ({ historyRecords = [], trackers = [] }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const HistoryTime = ({ record }) => {
    return (
      <div>
        <p className="date">{moment(record.date).format('HH:mm')}</p>
      </div>
    );
  };

  const HistoryIndicator = ({ record }) => {
    return (
      <div className="indicator pr-2 pl-2">
        <img
          src={navigationMethods.getSystemImageUrl(record.smallImage)}
          alt="record icon"
          with="24px"
          height="24px"
        />
      </div>
    );
  };

  const HistoryDetails = ({ record }) => {
    return (
      <Box>
        <Typography variant="body2" component={'span'}>{`${i18n.t(
          `trackingScreen:trackers.${record.name}`
        )}:`}</Typography>
        <Typography component={'span'}>
          &nbsp;
          {formatMeasurement(
            record.quantity,
            record.symbol,
            record.type,
            record.subType,
            record.additionalInfo
          )}
        </Typography>
      </Box>
    );
  };

  const handleEdit = (id, subTypeId) => {
    history.push(`${RELATIVE_PAGE_PATHS.TRACKING}/${subTypeId}/${id}`);
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteTrackingRecord(id));
    },
    [dispatch]
  );
  return (
    <>
      <Typography sx={{ marginTop: 2 }}>
        {i18n.t('breastfeedingScreen:history')}
      </Typography>
      <div className="history container">
        {historyRecords.map((record, index) => (
          <Fragment key={index}>
            <DateDivider
              current={record}
              previous={historyRecords[index - 1]}
            />
            <TrackerHistoryItem
              record={record}
              onDelete={handleDelete}
              onEdit={handleEdit}
              disableEdit={true}
              hideEdit={true}
            >
              <HistoryTime record={record} />
              <HistoryIndicator record={record} />
              <HistoryDetails record={record} />
            </TrackerHistoryItem>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default TrackerTotalHistory;
