import React from 'react';
import PoopDiaperFull from '../../common/icons/PoopDiaperFull';
import PoopDiaperLow from '../../common/icons/PoopDiaperLow';
import PoopDiaperMedium from '../../common/icons/PoopDiaperMedium';
import TrackerSlider from '../shared/TrackerSlider';
import * as i18n from 'i18next';
import { Box } from '@mui/system';

const marks = [
  {
    value: 0,
    label: i18n.t('trackingScreen:change-diapers.marks.0'),
  },
  {
    value: 1,
    label: i18n.t('trackingScreen:change-diapers.marks.1'),
  },
  {
    value: 2,
    label: i18n.t('trackingScreen:change-diapers.marks.2'),
  },
];

const icons = [
  <PoopDiaperLow fontSize="inherit" />,
  <PoopDiaperMedium fontSize="inherit" />,
  <PoopDiaperFull fontSize="inherit" />,
];

const images = (id) => icons[id]; //.map((x) => navigationMethods.getSystemImageUrl(x));

const DiaperSlider = ({ subType, ...rest }) => {
  return (
    <TrackerSlider
      marks={marks}
      min={0}
      max={2}
      step={1}
      subType={subType}
      {...rest}
    >
      {subType === 'poo-diaper'
        ? (value) => (
            <Box lineHeight="1.15" fontSize="64px">
              {images(value)}
            </Box>
          )
        : null}
    </TrackerSlider>
  );
};

export default DiaperSlider;
