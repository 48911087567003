import React from 'react';
import { Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
    downloadJournal,
    isAuthenticated,
    navigateToHomeScreen,
} from '../../actions';
import { EMPTY_STRING } from '../../utils/constants';
import LocalNavigationComponent from '../LocalNavigationComponent';

class DownloadJournalScreen extends React.Component {
    componentDidMount() {
        const params = queryString.parse(window.location.search);

        this.props.downloadJournal(params.data, params.token);
    }

    render() {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    {!isAuthenticated() ? (
                        <LocalNavigationComponent
                            header={EMPTY_STRING}
                            previousPage={this.props.navigateToHomeScreen}
                        />
                    ) : null}
                </Col>
            </Container>
        );
    }
}

export default connect(null, {
    downloadJournal,
    navigateToHomeScreen,
})(DownloadJournalScreen);
