import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'react-final-form';
import { TIME_OF_THE_DAY } from '../../../models/timeOfTheDay';

export interface TimeOfTheDayFieldProps {
  name: string;
}
export const TimeOfTheDayField = ({ name }: TimeOfTheDayFieldProps) => {
  const { input } = useField(name);
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="breast"
        row
        value={input.value}
        onBlur={input.onBlur}
        onChange={(e) => input.onChange(e.target.value)}
      >
        <Box display="grid" gridTemplateColumns={'1fr 1fr'} columnGap={2}>
          <FormControlLabel
            value={TIME_OF_THE_DAY.DAY}
            control={<Radio />}
            label="Дневен"
          />
          <FormControlLabel
            value={TIME_OF_THE_DAY.NIGHT}
            control={<Radio />}
            label="Нощен"
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};
