import i18next from 'i18next';

import bg from './translations/bg.json';

let userLang = 'bg';
let resourceFiles = bg;

i18next.init({
    interpolation: { escapeValue: false },
    lng: userLang,
    resources: resourceFiles,
});

export default i18next;
