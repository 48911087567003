import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  addTrackingRecord,
  updateTrackingRecord,
} from '../actions/trackingRecordsActions';
import TrackerForm from './tracker/TrackerForm';
import { orderBy } from 'lodash';
import { selectTracker } from '../actions/trackerActions';
import { useHistory } from 'react-router-dom';
import TrackerHeader from './tracker/TrackerHeader';

const TrackScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { typeId, recordId } = useParams();

  const tracker = useSelector((state) => {
    return (
      state.trackers.length && state.trackers.find((x) => `${x.id}` === typeId)
    );
  });

  const record = useSelector(
    (state) =>
      state.trackingRecords.length &&
      state.trackingRecords.find((x) => `${x.id}` === recordId)
  );

  useEffect(() => {
    dispatch(selectTracker(tracker));
    return () => dispatch(selectTracker(null));
  }, [tracker, dispatch]);

  const records = useSelector((state) =>
    orderBy(
      state.trackingRecords.filter((x) => `${x.subTypeId}` === typeId),
      (x) => new Date(x.date),
      ['desc']
    )
  );
  const handleSave = ({ id, subTypeId, quantity, date, notes }) => {
    if (id) {
      dispatch(updateTrackingRecord(id, date, quantity, notes));
      history.goBack();
    } else dispatch(addTrackingRecord(subTypeId, date, quantity, notes));
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (tracker)
    return (
      <>
        <TrackerHeader tracker={tracker} lastRecord={records[0] || {}} />
        <TrackerForm
          tracker={tracker}
          records={records}
          record={record}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </>
    );
  else return <div>Missing tracker</div>;
};

export default TrackScreen;
