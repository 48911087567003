import { Button, Stack } from '@mui/material';
import * as i18n from 'i18next';
import moment from 'moment';
import { useCallback } from 'react';
import { Form } from 'react-final-form';
import TrackerHistory from './TrackerHistory';
import TrackerInput from './TrackerInput';
import { TrackerDateTime } from './shared/TrackerDateTime';
import { TRACKERS_TYPE } from '../../utils/constants';

const TrackerForm = ({ tracker, records, record, onSave, onCancel }) => {
  const lastRecord = record || records[0] || {};
  const { quantity } = lastRecord;

  const onSubmit = useCallback(
    async ({ quantity, date, time, id }, form) => {
      const newRecord = {
        id,
        subTypeId: tracker.id,
        quantity,
        date: moment(date).toISOString(),
      };
      onSave && (await onSave(newRecord));
      form.reset();
    },
    [tracker.id, onSave]
  );
  const quantities = [0, 0, quantity || 120, 0, quantity || 1500];

  const initialValues = {
    id: record && record.id,
    quantity: (record && record.quantity) || quantities[tracker.type.id],
    date: moment(record && record.date).format('YYYY-MM-DD HH:mm'),
    // date: moment(record && record.date).format('YYYY-MM-DD'),
    // time: moment(record && record.date).format('HH:mm'),
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (tracker.type.name === TRACKERS_TYPE.CHANGE_DIAPERS) {
          } else {
            if (!values.quantity) {
              errors.quantity = 'Задължително поле';
            } else if (Number(values.quantity) <= 0) {
              errors.quantity = 'Стойността трябва да е по-голяма от 0.';
            }
          }

          if (!values.date) {
            errors.date = 'Задължително поле';
          }
          if (moment(values.date).isAfter(moment())) {
            errors.date = 'Датата не може да е в бъдещето.';
          }
          return errors;
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="tracker-form">
            <TrackerInput name="quantity" tracker={tracker} />
            <Stack direction="row" spacing={2} paddingY={4} width={'100%'}>
              <TrackerDateTime name="date" />
              {/* <Field name="date">
                {({ input }) => (
                  <TextField
                    fullWidth
                    id="date"
                    label="Дата"
                    type="date"
                    value={input.value}
                    onChange={input.onChange}
                    // sx={{ width: 150 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: moment().format('YYYY-MM-DD') }}
                  />
                )}
              </Field>
              <Field name="time">
                {({ input, meta }) => {
                  console.log(input.name, meta.error, meta.touched);
                  return (
                    <TextField
                      fullWidth
                      id="time"
                      label="Час"
                      type="time"
                      value={input.value}
                      onChange={input.onChange}
                      // sx={{ width: 150 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched && meta.error}
                    />
                  );
                }}
              </Field> */}
            </Stack>
            <Stack spacing={2} display="flex" alignItems="center" paddingY={4}>
              <Button type="submit" variant="contained">
                {record ? i18n.t('buttons:saveChanges') : i18n.t('buttons:add')}
              </Button>
              {record && (
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleCancel}
                  color="error"
                >
                  {i18n.t('buttons:Откажи')}
                </Button>
              )}
            </Stack>
          </form>
        )}
      </Form>
      <TrackerHistory historyRecords={records} />
    </>
  );
};

export default TrackerForm;
