import { FC } from 'react';
import {
  SleepTrackerButtonStartNightDisabledIcon,
  SleepTrackerButtonStartNightIcon,
  SleepTrackerButtonStopNightIcon,
} from '../../common/icons';

interface SleepNightButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}
export const SleepNightButton: FC<SleepNightButtonProps> = ({
  disabled = false,
  onClick,
  isActive = false,
}) => {
  if (isActive) {
    return <SleepTrackerButtonStopNightIcon onClick={onClick} />;
  }
  if (disabled) {
    return <SleepTrackerButtonStartNightDisabledIcon />;
  }
  return <SleepTrackerButtonStartNightIcon onClick={onClick} />;
};
