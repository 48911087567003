import { Box, Dialog, FormLabel } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FC, forwardRef, useMemo } from 'react';
import { Form } from 'react-final-form';
import { TimeOfTheDayField } from './TimeOfTheDayField';
import { TrackerDateTime } from '../shared/TrackerDateTime';
import {
  TIME_OF_THE_DAY,
  timeOfTheDayType,
} from '../../../models/timeOfTheDay';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ResultType = {
  startTime: Date;
  endTime: Date;
  timeOfTheDay: timeOfTheDayType;
  duration?: number;
};

interface SleepEditDialogProps {
  isOpen: boolean;
  onClose?: () => void;
  onSave?: (data: ResultType) => void;
  startTime?: Date | null;
  endTime?: Date | null;
  timeOfTheDay?: timeOfTheDayType;
  isEdit?: boolean;
}

export const SleepEditDialog: FC<SleepEditDialogProps> = ({
  isOpen,
  onSave,
  onClose,
  startTime: _startTime,
  endTime: _endTime,
  timeOfTheDay: _timeOfTheDay,
  isEdit,
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  const handleSubmit = (values: ResultType) => {
    onSave &&
      onSave({
        ...values,
        duration: moment(values.endTime).diff(
          moment(values.startTime),
          'seconds'
        ),
      });
    handleClose();
  };

  const initialValues = useMemo(
    () => ({
      startTime:
        _startTime || moment(moment().format('YYYY-MM-DD HH:mm')).toDate(),
      endTime:
        _endTime ||
        moment(moment().format('YYYY-MM-DD HH:mm')).add(1, 'minutes').toDate(),
      timeOfTheDay: _timeOfTheDay || TIME_OF_THE_DAY.DAY,
    }),
    [_startTime, _endTime, _timeOfTheDay]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={(values) => {
        const errors: Record<string, string> = {};
        if (!values.startTime) {
          errors.startTime = 'Required';
        }
        if (!values.endTime) {
          errors.endTime = 'Required';
        }
        if (!values.timeOfTheDay) {
          errors.timeOfTheDay = 'Required';
        }
        if (
          values.startTime &&
          values.endTime &&
          moment(values.startTime).isAfter(moment(values.endTime))
        ) {
          errors.startTime = 'Началното време трябва да е преди крайното.';
        }
        if (
          values.startTime &&
          values.endTime &&
          moment(values.endTime).diff(moment(values.startTime), 'minutes') < 1
        ) {
          errors.endTime = 'Времето за отчитане трябва да е поне 1 минута.';
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            onClose={onClose}
          >
            <DialogTitle>{isEdit ? 'Промени сън' : 'Добави сън'}</DialogTitle>
            <DialogContent>
              <TimeOfTheDayField name="timeOfTheDay" />
              <Box>
                <FormLabel>Начало</FormLabel>
                <TrackerDateTime name="startTime" />
                <FormLabel>Край</FormLabel>
                <TrackerDateTime name="endTime" />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                Затвори
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                {isEdit ? 'Промени' : 'Добави'}
              </Button>
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      )}
    </Form>
  );
};
