import { Grid, Link, Typography } from '@mui/material';
import * as i18n from 'i18next';
import { orderBy, filt } from 'lodash';
import React, { forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { showInfoModal } from '../../actions';
import {
  IsSleepTrackerEnabled,
  RELATIVE_PAGE_PATHS,
  TRACKERS_SUB_TYPE,
} from '../../utils/constants';
import navigationMethods from '../../utils/navigationUtils';
import TrackerItem from './TrackerItem';
import TrackerTotalHistory from './TrackerTotalHistory';
import { stat } from 'fs';

const TrackerListItem = forwardRef(({ tracker }, ref) => {
  let link = `/tracking/${tracker.id}`;

  if (tracker.name === TRACKERS_SUB_TYPE.BREAST_FEEDING) {
    link = RELATIVE_PAGE_PATHS.BREASTFEEDING;
  }
  if (tracker.name === TRACKERS_SUB_TYPE.SLEEP) {
    link = '/tracking/sleep';
  }

  return (
    <Grid item ref={ref}>
      <PaidLink to={link} isPaid={tracker.isPaid}>
        <TrackerItem
          name={i18n.t(`trackingScreen:trackers.${tracker.name}`)}
          icon={navigationMethods.getSystemImageUrl(tracker.smallImage)}
          isPaid={tracker.isPaid}
        />
      </PaidLink>
    </Grid>
  );
});

const TrackerList = () => {
  const trackers = useSelector((state) =>
    orderBy(
      state.trackers.filter(
        (x) => IsSleepTrackerEnabled || x.name !== TRACKERS_SUB_TYPE.SLEEP
      ),
      (x) => 100 * x.type.id + x.order
    )
  );
  const records = useSelector((state) =>
    orderBy(state.trackingRecords, (x) => new Date(x.date), ['desc'])
  );

  return (
    <>
      {trackers.length === 0 ? (
        <Typography
          variant="body2"
          className="details"
          sx={{ marginBottom: 4 }}
        >
          Тракерите са платена функционалност.
        </Typography>
      ) : (
        <>
          <Typography
            variant="body2"
            className="details"
            sx={{
              marginBottom: 2,
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            Ползването на ежедневните тракери е препоръчително при нужда от
            консултация.
            <br />
            <Link component={RouterLink} to={'/consult'}>
              Намерете или споделете със специалист.{' '}
            </Link>
          </Typography>
        </>
      )}
      <Grid container spacing={2} justifyContent="center">
        {trackers.map((tracker, i) => {
          return <TrackerListItem key={tracker.name} tracker={tracker} />;
        })}
      </Grid>
      <TrackerTotalHistory historyRecords={records} />
    </>
  );
};

const PaidLink = ({ to, isPaid, children }) => {
  const dispatch = useDispatch();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const paidStickerMsg = i18n.t(
        'trackingScreen:paid-trackers.not-paid-message'
      );
      dispatch(showInfoModal(paidStickerMsg));
    },
    [dispatch]
  );

  if (!isPaid)
    return (
      <Link component={RouterLink} to={to}>
        {children}
      </Link>
    );
  return <div onClick={handleClick}>{children}</div>;
};

export default TrackerList;
