import React from 'react';
import * as i18n from 'i18next';
import { reduxForm } from 'redux-form';
import { Button, Col, Container } from 'reactstrap';
import { FORMS, RELATIVE_PAGE_PATHS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';
import { connect } from 'react-redux';
import { Fields } from '../common/Fields';
import navigationMethods from '../../utils/navigationUtils';
import {
    addSpecialist,
    getSpecialist,
    getSpecialists,
    updateSpecialist,
    deleteSpecialist,
    showDeleteModal,
    closeModal,
    showInfoModal,
    shareJournal,
    showConfirmModal,
} from '../../actions';

class SpecialistDetailsScreen extends React.Component {
    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            if (!this.props.specialists.length) {
                this.props.getSpecialists();
            }
            this.props.getSpecialist(id);
        }
    }

    handleSubmit = (formValues) => {
        const id = this.props.match.params.id;
        if (id) {
            this.props.updateSpecialist(id, formValues);
        } else {
            this.props.addSpecialist(formValues);
        }
    };

    onDelete = () => {
        const id = this.props.match.params.id;
        const message = i18n.t('specialistsScreen:confirmDelete');
        this.props.showDeleteModal({
            message,
            onDelete: () => {
                this.props.deleteSpecialist(id);
                this.props.closeModal();
            },
        });
    };

    renderDeleteButton = () => {
        if (this.props.match.params.id) {
            return (
                <Button
                    onClick={this.onDelete}
                    className="greenBtn header next uppercase secondary danger mt-3"
                >
                    {i18n.t('buttons:delete')}
                </Button>
            );
        }
    };

    renderShareButton = () => {
        if (this.props.match.params.id) {
            return (
                <Button
                    onClick={() => {
                        if (!this.props.currentSpecialist.email) {
                            this.props.showInfoModal(
                                i18n.t('specialistsScreen:noEmailError')
                            );
                        } else {
                            this.props.showConfirmModal({
                                message: i18n.t(
                                    'specialistsScreen:confirmShareJournal'
                                ),
                                onConfirm: () => {
                                    this.props.shareJournal(
                                        this.props.currentSpecialist.email
                                    );
                                },
                            });
                        }
                    }}
                    className="greenBtn header next uppercase mt-3"
                >
                    {i18n.t('specialistsScreen:buttons.shareJournal')}
                </Button>
            );
        }
    };

    render() {
        const id = this.props.match.params.id;
        const specialistNumber =
            this.props.specialists.findIndex(
                (specialist) => specialist.id === id
            ) + 1;
        const isNewSpecialist =
            navigationMethods.getRoute() ===
            RELATIVE_PAGE_PATHS.ADD_SPECIALISTS;
        const title = isNewSpecialist
            ? i18n.t('specialistsScreen:addSpecialist')
            : i18n.t('specialistsScreen:specialist', {
                  word1: specialistNumber,
              });
        const buttonName = isNewSpecialist
            ? i18n.t('specialistsScreen:buttons.add')
            : i18n.t('buttons:saveChanges');

        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <form
                        onSubmit={this.props.handleSubmit(this.handleSubmit)}
                        className="flexContainerColumn flexContainerCentered profile-form fix-padding"
                    >
                        <div className="flexContainerCentered">
                            <p className="header mainGreenColor header-small uppercase">
                                {title}
                            </p>
                        </div>

                        <div className="flexContainerColumn flexContainerCentered mt-3">
                            {Fields.specialists.name()}
                            {Fields.specialists.specialty()}
                            <div className="mt-4">
                                {Fields.commonFields.email()}
                                {Fields.specialists.phone()}
                                {Fields.specialists.address({
                                    className:
                                        'specialists-address input-container',
                                    rows: 10,
                                })}
                                {Fields.specialists.isFamilyPhysician()}
                            </div>
                        </div>
                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred mt-5 mb-5"
                        >
                            <Button
                                onClick={this.props.handleSubmit(
                                    this.handleSubmit
                                )}
                                className="greenBtn header next uppercase"
                            >
                                {buttonName}
                            </Button>
                            {this.renderShareButton()}
                            {this.renderDeleteButton()}
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    }
}

const specialistForm = reduxForm({
    form: FORMS.SPECIALIST_FORM,
    validate: formUtils.validateSpecialists,
    destroyOnUnmount: true,
    enableReinitialize: true,
})(SpecialistDetailsScreen);

const mapStateToProps = (state, props) => {
    const id = props.match.params.id;
    return {
        initialValues: id ? state.specialists.specialist : {},
        specialists: state.specialists.specialists,
        currentSpecialist: id ? state.specialists.specialist : {},
    };
};

export default connect(mapStateToProps, {
    addSpecialist,
    getSpecialist,
    getSpecialists,
    updateSpecialist,
    deleteSpecialist,
    showDeleteModal,
    closeModal,
    showInfoModal,
    shareJournal,
    showConfirmModal,
})(specialistForm);
