import type { SVGProps } from 'react';
export const SleepTrackerSleepIconIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" {...props}>
    <path
      fill="#f2f3c4"
      d="M116.34 51.43c-.36 32.71-23.16 36.8-51.35 36.56-28.18-.24-50.99-5.33-50.72-37.43.25-28.91 23.16-36.8 51.35-36.56 28.18.24 51.07 5.34 50.72 37.43Z"
    />
    <ellipse
      cx={107.11}
      cy={30.31}
      fill="#f2f3c4"
      rx={11.4}
      ry={8.27}
      transform="rotate(-14.84 107.133 30.312)"
    />
    <ellipse
      cx={105.32}
      cy={73.53}
      fill="#f2f3c4"
      rx={8.27}
      ry={11.4}
      transform="rotate(-62.3 105.32 73.53)"
    />
    <ellipse
      cx={22.74}
      cy={28.5}
      fill="#f2f3c4"
      rx={8.27}
      ry={11.4}
      transform="rotate(-74.18 22.734 28.496)"
    />
    <ellipse
      cx={23.79}
      cy={71.74}
      fill="#f2f3c4"
      rx={11.4}
      ry={8.27}
      transform="rotate(-26.72 23.793 71.75)"
    />
    <path
      fill="#eeebb2"
      d="M14.75 31.46c-1.63-2.01-2.23-4.61-1.41-5.47 1.14-1.19 1.5 1.46 3.32 3.31 1.83 1.85 4.35 2.35 2.58 3.98-1.44 1.33-2.86.19-4.5-1.82Z"
    />
    <path
      fill="#f9f7d0"
      d="M110.51 23.18c2.56.37 4.76 1.87 4.75 3.06-.02 1.65-2.09-.05-4.68-.17-2.6-.12-4.79 1.23-4.6-1.17.15-1.95 1.97-2.09 4.54-1.72Z"
    />
    <path
      fill="#eeebb2"
      d="M16.26 60.56c.63 10.59.39 5.73.39 5.73 1.53.61 11.08 17.96 13.86 15.17 0 0-7.2-12-9.61-25.54-2.41-13.51 4.39-23.17 1.65-22.28-7.51 2.45-6.95 15.69-6.28 26.91Z"
    />
    <circle cx={19} cy={51.45} r={1.34} fill="#e8ec9e" />
    <circle cx={32.75} cy={25.65} r={1.34} fill="#e8ec9e" />
    <circle cx={18.65} cy={24.19} r={1.34} fill="#e8ec9e" />
    <circle cx={24.03} cy={43.26} r={1.34} fill="#e8ec9e" />
    <circle cx={50.26} cy={18.53} r={1.34} fill="#e8ec9e" />
    <circle cx={27.92} cy={61.73} r={1.34} fill="#e8ec9e" />
    <circle cx={30.33} cy={33.07} r={1.34} fill="#e8ec9e" />
    <circle cx={86.06} cy={20.17} r={1.34} fill="#e8ec9e" />
    <circle cx={97.72} cy={29.13} r={1.34} fill="#e8ec9e" />
    <circle cx={109.97} cy={28.25} r={1.34} fill="#e8ec9e" />
    <circle cx={105.54} cy={33.71} r={1.34} fill="#e8ec9e" />
    <circle cx={104.12} cy={43.31} r={1.34} fill="#e8ec9e" />
    <circle cx={108.58} cy={53.84} r={1.34} fill="#e8ec9e" />
    <circle cx={102.07} cy={64.54} r={1.34} fill="#e8ec9e" />
    <circle cx={105.97} cy={71.81} r={1.34} fill="#e8ec9e" />
    <circle cx={98.18} cy={73.08} r={1.34} fill="#e8ec9e" />
    <circle cx={25.72} cy={70.77} r={1.34} fill="#e8ec9e" />
    <circle cx={32.34} cy={73.6} r={1.34} fill="#e8ec9e" />
    <path
      fill="#f9f7d0"
      d="M114.68 46.69c-.53-4.82-2.45-9.87-3.91-10.75-2.03-1.22.12 3.74.35 8.42.23 4.7-1.38 7.55 1.58 9.05 2.4 1.22 2.51-1.9 1.98-6.72Z"
    />
    <path
      fill="#f2f3c4"
      d="M37.76 116.44c-4.2-.69-2.1-9.46-1.04-15.86 1.06-6.4 7.43-12.26 11.62-11.57 4.2.69 2.2 7.82 1.15 14.21-1.06 6.4-7.53 13.91-11.73 13.21Z"
    />
    <path
      fill="#eeebb2"
      d="M89.26 72.97c-4.54.16-.85 4.95-.85 4.95-3.31 1.3-12.06-.12-12.4-3.22 0 0 6.51-2.59 10.78-5.9 4.26-3.3-1.37-36.73.63-35.26 2.95 2.16 7.13 8.35 7.94 12.29.9 4.37 4.59 7.66 1.89 11.17-2.47 3.21-4.52 9.43-6.25 11.11-4.34 4.23 8.13 4.48-1.74 4.84ZM41.77 69.25c.73.62.55.79.07.77-1.78-.08-2.58 2.3-1.16 3.37 3.24 2.47 11.29 2.42 11.58.18 0 0-5.13-6.72-9.34-10.1s4.41-32.52 2.38-31.09c-2.99 2.11-7.73 8.67-9.1 9.3-6.54 3.02-6.62 10.69-.13 14.77 3.43 2.15-1.45 6.73 5.71 12.8Z"
    />
    <path
      fill="#e1e18b"
      d="M44.57 69.97c2.07 1.57-2.14 1.53-2.14 1.53-2.51 2.75 11.93 5.45 12.32 2.36 0 0-5.13-6.72-9.34-10.1s4.41-32.52 2.38-31.09c-2.99 2.11-7.73 8.67-9.1 9.3-6.54 3.02-6.88 9.66-1.83 13.98 2.39 2.04.22 8.37 7.71 14.03ZM85.99 71.04c-2.17 1.17 1.98 1.7 1.98 1.7 2.25 2.88-12.16 3.46-12.3.55 0 0 5.58-5.54 9.99-8.11 4.41-2.57-1.74-30.72.14-29.12 2.77 2.35 6.91 9.06 8.21 9.83 6.2 3.67 5.99 9.87.69 13.19-2.51 1.57-.88 7.73-8.7 11.97Z"
    />
    <path
      fill="#fdd7bb"
      d="M14.02 51.47c1.38-1.24 3.29.88 4.74 3.67-.69-2.14-1.2-3.32-.15-3.75.93-.38 2.49.68 3.86 3.46.74 1.51 2.11 3.69 2.13 3.74.05-2.45 1.1-4.04 2.72-2.94 1.13.76.12 1.93 1.03 6.26.56 2.66-.25 5.62-3.74 8.23-4.49 2.76-7.16.52-9.31-1.55-2.34-2.26-2.23-3.59-2.77-4.6-1.13-2.1-3.89-3.79-2.77-4.77.6-.52 2.49.15 4.13 1.47-.08-.17-4.14-4.43-2.91-5.52.82-.73 3.63.83 4.92 2.61-1.61-3.38-2.97-5.35-1.91-6.31Z"
    />
    <path
      fill="#e8b399"
      d="M24.39 64.57c.54.41-1.62-1.03-1.55-1.87.17-2.03 1.35-3.59 1.35-3.59s-.77 2.76-.58 3.7c.09.44.49 1.54.78 1.76ZM21.12 57.56c.51.14-1.37.02-1.63-.44-.26-.46-1.26-3.79-1.26-3.79s1.11 2.81 1.72 3.57c.28.35.9.59 1.17.66Z"
    />
    <path
      fill="#f2c2a8"
      d="M15.4 65.71c-.21.67-2.31-.83 0-3.42 2.38-2.68 5.51-3.96 5.51-3.96s-2.69 2.04-3.78 3.45c-.97 1.25-1.62 3.57-1.73 3.93Z"
    />
    <path
      fill="#e8b399"
      d="M16.87 58.52s-.18.52-.44.2-1.29-2.81-1.29-2.81l1.73 2.61ZM14.01 62.41c-.42.35.8-.47.61-.82-.19-.35-1.98-2.6-1.98-2.6s2.23 2.68 1.36 3.42Z"
    />
    <path
      fill="#fdd7bb"
      d="M39.87 95.42c-1.62 1.34-9.71-4.61-14.57-12.54-4.91-8.03-11.99-17.48-1.63-18.29 6.07-.47 11.24 10.63 14.11 15.39 3.36 5.57 8.79 9.89 2.08 15.43ZM115.55 53.46c-1.41-1.2-3.27.94-4.65 3.75.64-2.13 1.13-3.32.06-3.72-.94-.35-2.48.73-3.77 3.52-.7 1.51-2.02 3.7-2.04 3.75-.11-2.42-1.19-3.98-2.79-2.86-1.11.78-.08 1.92-.89 6.23-.5 2.65.39 5.56 3.93 8.08 4.56 2.63 7.17.36 9.27-1.74 2.29-2.3 2.14-3.61 2.66-4.62 1.08-2.11 3.8-3.84 2.65-4.8-.61-.51-2.48.21-4.1 1.55.07-.17 4.03-4.49 2.78-5.54-.84-.7-3.61.9-4.86 2.7 1.53-3.38 2.85-5.37 1.76-6.3Z"
    />
    <path
      fill="#e8b399"
      d="M105.49 66.68c-.53.42 1.59-1.06 1.51-1.89-.22-2.01-1.43-3.53-1.43-3.53s.83 2.72.66 3.66c-.08.43-.45 1.53-.74 1.76ZM108.59 59.66c-.5.15 1.37 0 1.62-.47s1.17-3.79 1.17-3.79-1.04 2.81-1.64 3.57c-.28.36-.89.61-1.15.69Z"
    />
    <path
      fill="#f2c2a8"
      d="M114.5 67.62c.23.66 2.29-.87-.08-3.39-2.45-2.6-5.6-3.81-5.6-3.81s2.74 1.97 3.86 3.34c.99 1.22 1.7 3.51 1.82 3.86Z"
    />
    <path
      fill="#e8b399"
      d="M112.86 60.51s.19.51.44.19 1.23-2.82 1.23-2.82l-1.67 2.62ZM115.81 64.31c.43.34-.81-.45-.63-.8s1.91-2.62 1.91-2.62-2.17 2.71-1.28 3.42Z"
    />
    <path
      fill="#fdd7bb"
      d="M90.74 97.61c1.65 1.29 9.6-4.79 14.27-12.76 4.72-8.07 11.58-17.6 1.2-18.17-6.08-.33-10.99 10.79-13.75 15.58-3.22 5.6-8.56 10-1.72 15.35Z"
    />
    <ellipse
      cx={67.68}
      cy={82.53}
      fill="#80c094"
      rx={8.09}
      ry={25.9}
      transform="rotate(-89.51 67.682 82.536)"
    />
    <path
      fill="#80c094"
      d="M83.87 101.98c-4.49-3.33-10.64-23.21-1.63-24.8 22.27-3.93 15.15-10.22 20.1-7.61 12.71 6.69 8.54 10.53-.62 21.8-9.16 11.27-13.37 13.94-17.86 10.61ZM45.9 100.38c4.54-3.25 11.04-23.02 2.05-24.77-22.2-4.31-14.98-10.48-19.97-7.96-12.82 6.48-8.72 10.39.25 21.81 8.96 11.42 13.13 14.17 17.68 10.92Z"
    />
    <path
      fill="#79b78b"
      d="M44.26 97.13c.97-2.69 1.36-6.07.91-7.13-.62-1.47-1.4 1.8-1.95 4.46-1.06 5.07-6.83 3.33-5.18 4.81 2.68 2.39 5.25.55 6.22-2.14Z"
    />
    <path
      fill="#f3ccb3"
      d="M75.26 76.53c-.25 1.89-4.02 3.42-8.94 3.38-4.92-.04-8.9-1.62-8.88-3.53.05-5.3 4.02-3.42 8.94-3.38s9.66-2.35 8.88 3.53Z"
    />
    <ellipse
      cx={65.88}
      cy={50.77}
      fill="#fdd7bb"
      rx={25.88}
      ry={28.98}
      transform="rotate(-87.54 65.885 50.764)"
    />
    <ellipse
      cx={94.23}
      cy={51.98}
      fill="#fdd7bb"
      rx={6.29}
      ry={4.79}
      transform="rotate(-87.54 94.227 51.98)"
    />
    <path
      fill="#eb9"
      d="M66.64 53.17c.87.03 1.59.49 1.82 1.1.07-.15.12-.3.12-.47.03-.82-.83-1.52-1.91-1.55-1.08-.03-1.98.6-2 1.43 0 .16.03.31.09.45.28-.58 1.02-.98 1.88-.96Z"
    />
    <path
      fill="#e5ac97"
      d="M70.37 25.04c-1.26 1.72-3.18 2.52-4.73 2.14.2.35.44.66.77.9 1.62 1.19 4.19.43 5.75-1.69 1.56-2.12 1.52-4.8-.1-5.99-.31-.23-.66-.36-1.02-.45.78 1.36.57 3.4-.67 5.09Z"
    />
    <path
      fill="#e5ac97"
      d="M67.35 25.84c-.8 1.08-2.01 1.59-2.98 1.35.12.22.28.42.48.57 1.02.75 2.64.27 3.63-1.06.98-1.34.96-3.03-.06-3.78a1.67 1.67 0 0 0-.64-.28c.49.86.36 2.15-.42 3.21Z"
    />
    <ellipse
      cx={36.96}
      cy={48.84}
      fill="#fdd7bb"
      rx={5.74}
      ry={4.37}
      transform="rotate(-87.54 36.956 48.845)"
    />
    <path
      fill="#c64d59"
      d="M66.73 64.4c-1.48-.02-2.93-.82-2.93-1.33 0-.99.8-2.04 2.11-2.47.24-.08.51-.12.8-.11 2.1.09 2.99 1.64 2.96 2.81-.09.75-1.57 1.12-2.93 1.1Z"
    />
    <path
      fill="#e87880"
      d="M66.76 63.16c-1.03-.05-1.94.27-2.2.69.55.32 1.27.51 2.08.55.89.05 1.68-.07 2.26-.34-.21-.44-1.12-.85-2.14-.9Z"
    />
    <path
      fill="#e8b491"
      d="M36.7 49.52c1.28 1.06.66 2.1-.02 2.09s-1.22-1.08-1.21-2.4c.01-1.32.57-2.38 1.25-2.38s-1.04 1.84-.02 2.68ZM95.21 52.47c-1.29 1.04-.7 2.08-.02 2.09s1.24-1.06 1.25-2.38-.53-2.39-1.21-2.4 1 1.86-.02 2.68Z"
    />
    <path
      fill="#e5ac97"
      d="M53.19 40.41c-2.2.53-4.4 2.24-4.26 2.84.03.13.12.18.17.2.03.01.06.02.09.02.14 0 .29-.11.55-.3.47-.34 1.34-.98 2.71-1.32 1.2-.29 2.5-.27 3.47-.26 1.06.01 1.58.02 1.66-.33.05-.2-.02-.4-.18-.56-.59-.58-2.44-.71-4.21-.28ZM84.08 44.62c-.25-.66-1.73-2.34-3.65-3.19-1.66-.74-3.5-.95-4.19-.48-.19.13-.29.31-.28.52.02.36.54.45 1.57.63.94.16 2.23.38 3.36.88 1.29.57 2.03 1.36 2.43 1.78.24.25.37.39.53.39h.05c.05 0 .15-.04.2-.17.04-.1.04-.22-.02-.37Z"
    />
    <path
      fill="#a47362"
      d="M55.23 51.26c-1.84-.09-3.87-1.13-3.85-1.63 0-.11.07-.16.1-.19.02-.01.05-.02.07-.03.11-.02.25.04.48.15.43.2 1.23.58 2.37.64 1.01.05 2.05-.17 2.82-.32.85-.17 1.26-.26 1.38.01.07.15.05.33-.06.48-.38.56-1.84.94-3.33.87ZM81.22 50.48c-.43.39-2.13 1.06-3.84.96-1.48-.08-2.89-.61-3.22-1.21a.46.46 0 0 1 0-.48c.15-.26.56-.13 1.38.13.75.23 1.76.55 2.77.61 1.15.06 1.98-.23 2.43-.39.27-.1.42-.15.53-.09.01 0 .02.01.03.02.03.03.09.09.08.2 0 .09-.05.17-.15.26Z"
    />
    <path
      fill="#f2f3c4"
      d="M77.42 128.16c-10.47-2.15-20.07 1.55-18.03-7.43 1.65-7.3 9.1-6.63 19.29-3.56 8.22 2.48 15.09 7.59 17.08 11.83h3.47c-1.11-3.25-1.98-6.61-2.28-10.04-3.14-35.07-7.71-48.92-20.2-45.15.58 1.09.5.93.91 2.11 4.17 12.12-5.03 13.25-11.27 13.42-8.76.24-13.05-5.62-11.33-13.61.26-1.2.32-.97.9-2.04-5.18-1.37-13.53-.05-11.59 1.22 2.49 1.63 2.24 8.68 1.22 16.13-.51 3.79-1.09 12.17-5.94 11.02-3.19-.76-5.72 10.21-6.64 17.93-.36 3.05-.95 6.06-1.62 9h49.23c-1.03-.33-2.09-.62-3.18-.84Zm8.81-16.1c-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94 1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94Zm-.36-23.24c1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94Zm-11.39 8.74c1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94Zm-9.16 12.76c1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94-1.08 0-1.95-.89-1.94-1.97s.89-1.95 1.97-1.94Zm-21.83 1.37c-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94 1.08 0 1.95.89 1.94 1.97s-.89 1.95-1.97 1.94Zm5.58-25.32c0-1.08.89-1.95 1.97-1.94 1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94s-1.95-.89-1.94-1.97Zm1.64 10.58c0-1.08.89-1.95 1.97-1.94 1.08 0 1.95.89 1.94 1.97 0 1.08-.89 1.95-1.97 1.94s-1.95-.89-1.94-1.97Zm2.8 23.88c-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94s1.95.89 1.94 1.97c0 1.08-.89 1.95-1.97 1.94Z"
    />
    <path
      fill="#f9f7d0"
      d="M78.68 117.17c-10.19-3.07-17.64-3.74-19.29 3.56-2.03 8.98 7.57 5.28 18.03 7.43 1.1.23 2.16.51 3.18.84h5.25c.36-.52.95-.86 1.63-.85.66 0 1.24.34 1.59.85h6.68c-1.99-4.24-8.86-9.35-17.08-11.83Zm-3.38 6.2c-1.08 0-1.95-.89-1.94-1.97 0-1.08.89-1.95 1.97-1.94 1.08 0 1.95.89 1.94 1.97s-.89 1.95-1.97 1.94Z"
    />
    <path
      fill="#e8ec9e"
      d="M53.54 116.93c-1.08 0-1.96.86-1.97 1.94 0 1.08.86 1.96 1.94 1.97 1.08 0 1.96-.86 1.97-1.94 0-1.08-.86-1.96-1.94-1.97ZM43.52 107.79c-1.08 0-1.96.86-1.97 1.94 0 1.08.86 1.96 1.94 1.97a1.955 1.955 0 1 0 .03-3.91ZM54.62 96.99c0-1.08-.86-1.96-1.94-1.97-1.08 0-1.96.86-1.97 1.94a1.955 1.955 0 1 0 3.91.03ZM75.34 119.47c-1.08 0-1.96.86-1.97 1.94 0 1.08.86 1.96 1.94 1.97a1.955 1.955 0 1 0 .03-3.91ZM65.28 114.23c1.08 0 1.96-.86 1.97-1.94 0-1.08-.86-1.96-1.94-1.97a1.955 1.955 0 1 0-.03 3.91ZM74.44 101.47c1.08 0 1.96-.86 1.97-1.94 0-1.08-.86-1.96-1.94-1.97-1.08 0-1.96.86-1.97 1.94 0 1.08.86 1.96 1.94 1.97ZM87.49 128.15c-.68 0-1.27.33-1.63.85h3.22c-.35-.51-.93-.84-1.59-.85ZM85.83 92.73c1.08 0 1.96-.86 1.97-1.94 0-1.08-.86-1.96-1.94-1.97-1.08 0-1.96.86-1.97 1.94 0 1.08.86 1.96 1.94 1.97ZM86.27 108.15c-1.08 0-1.96.86-1.97 1.94a1.955 1.955 0 1 0 3.91.03c0-1.08-.86-1.96-1.94-1.97ZM52.97 86.41c0-1.08-.86-1.96-1.94-1.97-1.08 0-1.96.86-1.97 1.94a1.955 1.955 0 1 0 3.91.03Z"
    />
  </svg>
);
