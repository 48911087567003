import React from 'react';

const Diary = ({ width, height }) => {
    const viewBox = '0 0 20.117 18.705';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            <path
                id="Икона_-_ДНЕВНИК"
                data-name="Икона - ДНЕВНИК"
                d="M-1316.042,19h-11.916a1,1,0,0,1-1-1V16.391h13.916V18A1,1,0,0,1-1316.042,19Zm1-3.914h-13.916v-2.61h13.916v2.609Zm0-3.915h-13.916V8.561h-2.1a.984.984,0,0,1-.955-.7.984.984,0,0,1,.386-1.121l9.056-6.266A1,1,0,0,1-1322,.295a1,1,0,0,1,.569.178l9.057,6.266a.983.983,0,0,1,.385,1.12.984.984,0,0,1-.955.7h-2.1V11.17Z"
                transform="translate(1332.058 -0.295)"
                fill="#87ba3b"
            />
        </svg>
    );
};

export default Diary;
