import * as i18n from 'i18next';
import moment from './moment';
import { TRACKERS_SUB_TYPE, TRACKERS_TYPE } from './constants';
import { formatDuration } from './timeUtils';

const formatMeasurement = (quantity, symbol, type, subType, additionalInfo) => {
  let _quantity = quantity;

  if (type && type === TRACKERS_TYPE.CHANGE_DIAPERS && subType)
    _quantity = i18n.t(`trackingScreen:${type}.${subType}.${quantity}`);

  if (
    subType === TRACKERS_SUB_TYPE.BREAST_FEEDING ||
    subType === TRACKERS_SUB_TYPE.SLEEP
  ) {
    const result = i18n.t(`trackingScreen:${type}.${subType}`, {
      quantity: formatDuration(moment.duration(quantity, 'seconds')),
      additionalInfo: i18n.t(`trackingScreen:tracking.${additionalInfo}`),
    });
    return result;
  }

  return symbol
    ? `${_quantity} ${i18n.t(`trackingScreen:symbols.${symbol}`)}`
    : `${_quantity}`;
};

export default formatMeasurement;
