import { Box, Typography } from '@mui/material';
import React from 'react';
import Lock from '../common/svgComponents/GoalsAndTagsIcons/Lock';

const TrackerItem = ({ name, icon, isPaid }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={'center'}
      maxWidth="54px"
      textAlign={'center'}
      position="relative"
    >
      <img alt="tracker" src={icon} width={'54px'} height={'54px'} />
      {isPaid && <Lock style={{ position: 'absolute', borderRadius: '50%' }} />}
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
};

export default TrackerItem;
