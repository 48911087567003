import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import * as i18n from 'i18next';
import navigationMethods from '../../utils/navigationUtils';
import {
  hideHamburgerMenu,
  logout,
  showConfirmModal,
  closeModal,
} from '../../actions';
import { Col, Container } from 'reactstrap';
import MenuItem from './MenuItem';

class HamburgerMenu extends React.Component {
  renderMenuItems = () => {
    const { previousPage } = this.props;

    const menuItems = [
      {
        title: i18n.t('hamburgerMenu:myProfile'),
        action: () => {
          navigationMethods.navigate(RELATIVE_PAGE_PATHS.PROFILE);
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: 'Тракери',
        action: () => {
          navigationMethods.navigate(RELATIVE_PAGE_PATHS.TRACKING);
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:myConsultants'),
        action: () => {
          navigationMethods.navigate(RELATIVE_PAGE_PATHS.SPECIALISTS);
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:aboutTheApp'),
        action: () => {
          navigationMethods.openUrlInNewTab('https://www.namama.bg/app');
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:payment'),
        action: () => {
          navigationMethods.openUrlInNewTab(
            'https://shop.namama.bg/bg-product-details-4.html'
          );
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:downloadJournal'),
        action: () => {
          navigationMethods.navigate(
            RELATIVE_PAGE_PATHS.REQUEST_JOURNAL_DOWNLOAD
          );
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:printJournal'),
        action: () => {
          navigationMethods.openUrlInNewTab(
            'https://www.namama.bg/page/app-print/'
          );
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:survey'),
        action: () => {
          navigationMethods.openUrlInNewTab(
            'https://www.namama.bg/app-anketi/'
          );
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:termsOfUse'),
        action: () => {
          navigationMethods.openUrlInNewTab(
            'https://www.namama.bg/page/obshti-usloviya/'
          );
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:gdpr'),
        action: () => {
          navigationMethods.openUrlInNewTab('https://www.namama.bg/GDPR/');
          this.props.hideHamburgerMenu();
        },
      },
      {
        title: i18n.t('hamburgerMenu:exit'),
        action: () => {
          this.props.showConfirmModal({
            message: i18n.t('hamburgerMenu:confirmExit'),
            onConfirm: () => {
              this.props.closeModal();
              this.props.logout();
              this.props.hideHamburgerMenu();
            },
          });
        },
      },
    ];

    return menuItems.map((item, index) => {
      return (
        <MenuItem
          title={item.title}
          previousPage={previousPage}
          onClick={item.action}
          key={index}
        />
      );
    });
  };

  render() {
    if (this.props.showHamburgerMenu === 'DISABLED') {
      return <Fragment />;
    }

    const hamburgerContainerClass =
      this.props.showHamburgerMenu === 'HIDE'
        ? 'noPadding menu-disabled'
        : 'noPadding';
    const hamburgerMenuClass =
      this.props.showHamburgerMenu === 'HIDE'
        ? 'hamburger-menu hamburger-menu-disabled'
        : 'hamburger-menu';
    return (
      <Container className={hamburgerContainerClass}>
        <Col xs={12} sm={12} md={4} lg={4} className="centred noPadding">
          <div className={hamburgerMenuClass}>{this.renderMenuItems()}</div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return { showHamburgerMenu: state.common.showHamburgerMenu };
};

export default connect(mapStateToProps, {
  hideHamburgerMenu,
  logout,
  showConfirmModal,
  closeModal,
})(HamburgerMenu);
