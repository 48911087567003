import React, { Component } from 'react';
import Read from './svgComponents/MenuBarIcons/Read';
import Add from './svgComponents/MenuBarIcons/Add';
import DetectTime from './svgComponents/MenuBarIcons/DetectTime';
import Diary from './svgComponents/MenuBarIcons/Diary';
import MainMenu from './svgComponents/MenuBarIcons/MainMenu';
import { connect } from 'react-redux';
import {
    showHamburgerMenu,
    hideHamburgerMenu,
    startNavLoader,
} from '../../actions';
import { Col } from 'reactstrap';
import navigationMethods from '../../utils/navigationUtils';
import { RELATIVE_PAGE_PATHS } from '../../utils/constants';
import * as i18n from 'i18next';

class FooterNavigationBar extends Component {
    onMainMenuClick = () => {
        if (this.props.isHamburgerMenuShown === 'SHOW') {
            this.props.startNavLoader();
            this.props.hideHamburgerMenu();
        } else {
            this.props.showHamburgerMenu();
        }
    };

    navigateTo = (path) => {
        navigationMethods.navigate(path);
        this.props.hideHamburgerMenu();
    };

    render() {
        const footerClass = this.props.className
            ? 'flexContainer footerNavBar ' + this.props.className
            : 'flexContainer footerNavBar';

        return (
            <Col className={footerClass}>
                <div
                    onClick={() => {
                        this.navigateTo(RELATIVE_PAGE_PATHS.USEFUL_INFO);
                    }}
                    style={{
                        padding: '0px 12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    <Read width={24} height={24} />
                    <span>{i18n.t('footerNavigationBar:read')}</span>
                </div>
                <div
                    onClick={() => {
                        this.navigateTo(RELATIVE_PAGE_PATHS.HOME);
                    }}
                    style={{
                        padding: '0px 12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    <Add width={24} height={24} />
                    <span>{i18n.t('footerNavigationBar:add')}</span>
                </div>
                <div
                    onClick={() => {
                        this.navigateTo(RELATIVE_PAGE_PATHS.TRACKING);
                    }}
                    style={{
                        padding: '0px 12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    <DetectTime width={24} height={24} />
                    <span>{i18n.t('footerNavigationBar:detectTime')}</span>
                </div>
                <div
                    onClick={() => {
                        this.navigateTo(RELATIVE_PAGE_PATHS.JOURNAL);
                    }}
                    style={{
                        padding: '0px 12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    <Diary width={24} height={24} />
                    <span>{i18n.t('footerNavigationBar:journal')}</span>
                </div>
                <div
                    onClick={this.onMainMenuClick}
                    style={{
                        padding: '0px 12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: 12,
                    }}
                >
                    <MainMenu width={24} height={21} />
                </div>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    return { isHamburgerMenuShown: state.common.showHamburgerMenu };
};

export default connect(mapStateToProps, {
    showHamburgerMenu,
    hideHamburgerMenu,
    startNavLoader,
})(FooterNavigationBar);
