import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router';
import { getAllTrackers } from '../actions/trackerActions';
import { getAllTrackingRecords } from '../actions/trackingRecordsActions';
import TrackScreen from './TrackScreen';
import ScreenContainer from './common/Container';
import TrackerList from './tracker/TrackerList';
import { IsSleepTrackerEnabled } from '../utils/constants';
import { SleepTracker } from './tracker/sleep/SleepTracker';

const TrackingScreen = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTrackers());
    dispatch(getAllTrackingRecords());
  }, [dispatch]);

  return (
    <ScreenContainer>
      <Switch>
        <Route exact path={path}>
          <TrackerList />
        </Route>
        {IsSleepTrackerEnabled && (
          <Route exact path={`${path}/sleep`}>
            <SleepTracker />
          </Route>
        )}
        {IsSleepTrackerEnabled && (
          <Route exact path={`${path}/sleep/:recordId`}>
            <SleepTracker />
          </Route>
        )}
        <Route exact path={`${path}/:typeId`}>
          <TrackScreen />
        </Route>
        <Route exact path={`${path}/:typeId/:recordId`}>
          <TrackScreen />
        </Route>
      </Switch>
    </ScreenContainer>
  );
};

export default TrackingScreen;
