import { getAxiosWithToken } from '../utils/webApi';
import { SAVE_PAID_FUNCTIONALITY_CREDENTIALS } from './actionTypes';
import { setPaidFunctionalityCredentialsInLocalStorage } from './authenticationActions';
import {
    showSuccessModal,
    startLoader,
    stopLoader,
    closeModal,
} from './commonActions';
import * as i18n from 'i18next';

export const getPaidFunctionalityCredentials = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'user/validate-credentials-for-unlocking-paid-stickers',
        data
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        setPaidFunctionalityCredentialsInLocalStorage(data);

        dispatch({
            type: SAVE_PAID_FUNCTIONALITY_CREDENTIALS,
            payload: data,
        });
    }
};

export const unlockPaidFunctionality = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'user/unlock-paid-stickers',
        data
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        dispatch(
            showSuccessModal(i18n.t('paidFunctionalityScreen:successfulUnlock'))
        );
    }
};

export const lockPaidFunctionality = (data) => async (dispatch) => {
    dispatch(startLoader());

    const response = await getAxiosWithToken().post(
        'user/lock-paid-stickers',
        data
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        dispatch(
            showSuccessModal(i18n.t('paidFunctionalityScreen:successfulLock'))
        );
    }
};

export const deleteUserProfiles = (data) => async (dispatch) => {
    dispatch(closeModal());
    dispatch(startLoader());

    const response = await getAxiosWithToken().delete(
        'user/delete-user-profiles-by-staff',
        { data }
    );

    dispatch(stopLoader());

    const returnedData = response.data;

    if (returnedData.success) {
        dispatch(
            showSuccessModal(i18n.t('paidFunctionalityScreen:successfulDelete'))
        );
    }
};
