import axios from 'axios';
import { history, store } from '../index';
import {
    BACKEND_BASE_URL,
    RELATIVE_PAGE_PATHS,
    REQUEST_RESPONSE_CODE,
} from './constants';
import {
    showErrorModal,
    forceLogout,
    stopLoader,
    closeModal,
} from '../actions';

export const getAxiosWithoutToken = () => {
    let instance = axios.create({
        baseURL: BACKEND_BASE_URL,
        headers: getDefaultHeaders(),
    });

    return configureAxios(instance);
};

// This is deliberately exported as function (instead of simply 'export axios.create(...)').
// The other version was causing a problem with the token - adding a ' ' in the beginning.
// And this is used as a workaround.
export const getAxiosWithToken = (
    isMultipartFormData,
    serverValidationErrorCallback
) => {
    let instance = axios.create({
        baseURL: BACKEND_BASE_URL,
        headers: {
            ...(isMultipartFormData
                ? getMultipartHeaders()
                : getDefaultHeaders()),
            Authorization: 'Bearer ' + store.getState().authentication.token,
        },
    });

    return configureAxios(instance, serverValidationErrorCallback);
};

const getDefaultHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': 'bg',
        'Cache-Control': 'no-cache',
    };
};

const getMultipartHeaders = () => {
    return {
        'Content-Type': 'multipart/form-data',
    };
};

const configureAxios = (axiosInstance, serverValidationErrorCallback) => {
    axiosInstance.interceptors.response.use(
        (response) => {
            serverValidationErrorHandling(
                response,
                serverValidationErrorCallback
            );
            return response;
        },
        (error) => {
            store.dispatch(stopLoader());
            store.dispatch(closeModal());
            serverDefaultErrorHandling(error);

            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const serverValidationErrorHandling = (
    response,
    serverValidationErrorCallback
) => {
    const { data } = response;
    if (
        !data.success &&
        data.code === REQUEST_RESPONSE_CODE.NOT_ACCEPTABLE_406 &&
        data.userFriendlyMessage
    ) {
        if (typeof serverValidationErrorCallback === 'function') {
            serverValidationErrorCallback();
        }

        store.dispatch(showErrorModal(response.data.userFriendlyMessage));
    }
};

const serverDefaultErrorHandling = (error) => {
    if (!error.response) {
        history.push(RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR);
        return;
    }
    switch (error.response.status) {
        case REQUEST_RESPONSE_CODE.NOT_ACCEPTABLE_406:
            // NOTE: Do nothing if the HTTP status code is 406 ("not acceptable").
            // This HTTP status code is "reserved" as a way for the backend to
            // signal there is an error WITHOUT supplying user-friendly error message.
            // The frontend is supplying the user-friendly error message in this
            // particular case
            break;

        case 404:
            history.push(RELATIVE_PAGE_PATHS.NOT_FOUND);
            break;

        case 401:
            store.dispatch(forceLogout());
            break;

        case 500:
            history.push(RELATIVE_PAGE_PATHS.INTERNAL_SERVER_ERROR);
            break;

        default:
            history.push(RELATIVE_PAGE_PATHS.UNHANDLED_ERROR);
            break;
    }

    return Promise.reject(error);
};
