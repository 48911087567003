import React from 'react';

const RightPlayButton = ({ onClick, color }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150.24 150.24"
            onClick={onClick}
        >
            <title>Button_pause</title>
            <g id="Rectangle_1888" data-name="Rectangle 1888">
                <rect
                    x="20"
                    y="19"
                    width="112"
                    height="112"
                    rx="11"
                    fill="#fff"
                />
                <path
                    d="M121,131H31a11,11,0,0,1-11-11V30A11,11,0,0,1,31,19h90a11,11,0,0,1,11,11v90A11,11,0,0,1,121,131ZM31,21a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
                    fill={color ? color : '#cacaca'}
                />
            </g>
            <path
                id="Polygon_9"
                data-name="Polygon 9"
                d="M88.41,60.37a4,4,0,0,1,1.33,5.5,3.94,3.94,0,0,1-1.33,1.33L73.09,76.56A4,4,0,0,1,67,73.15V54.41a4,4,0,0,1,4-4,4,4,0,0,1,2.09.59Z"
                fill={color ? color : '#cacaca'}
            />
            <g id="лява" style={{ isolation: 'isolate' }}>
                <g style={{ isolation: 'isolate' }}>
                    <path
                        d="M54.36,108.63a.83.83,0,0,1-.92-.95V105.6a.87.87,0,0,1,1-1,5.83,5.83,0,0,0,.68-1.79,13.21,13.21,0,0,0,.2-2.5V99.16A1.05,1.05,0,0,1,56.5,98h4.1a1.05,1.05,0,0,1,1.19,1.19v5.46h.37a.87.87,0,0,1,1,1v2.08c0,.63-.3.95-.9.95s-.9-.32-.9-.95v-1.4H55.24v1.4C55.24,108.31,55,108.63,54.36,108.63Zm2-4H59.8v-5H57.13v1.23a10.09,10.09,0,0,1-.21,2.07A6,6,0,0,1,56.31,104.62Z"
                        fill={color ? color : '#cacaca'}
                    />
                    <path
                        d="M71.19,106.42a.88.88,0,0,1-1-1v-2h-.88a1.5,1.5,0,0,0-.88.27,2.24,2.24,0,0,0-.62.68L67,105.82a1,1,0,0,1-.69.57,1.13,1.13,0,0,1-.77-.12,1.08,1.08,0,0,1-.49-.6.91.91,0,0,1,.13-.83l.53-.89a2.57,2.57,0,0,1,.49-.57,2.44,2.44,0,0,1,.65-.39,2.26,2.26,0,0,1-2.11-2.44,2.33,2.33,0,0,1,.82-1.89A3.61,3.61,0,0,1,67.94,98H71.1a.94.94,0,0,1,1.08,1v6.4a1,1,0,0,1-.28.74A1,1,0,0,1,71.19,106.42Zm-2.8-4.4H70.2V99.36H68.39a1.92,1.92,0,0,0-1.25.35,1.22,1.22,0,0,0-.4,1,1.25,1.25,0,0,0,.4,1A1.86,1.86,0,0,0,68.39,102Z"
                        fill={color ? color : '#cacaca'}
                    />
                    <path
                        d="M78.79,106.45a4.74,4.74,0,0,1-2.28-.53A3.78,3.78,0,0,1,75,104.4a4.87,4.87,0,0,1-.52-2.32A4.68,4.68,0,0,1,75,99.8a3.63,3.63,0,0,1,1.5-1.48,4.74,4.74,0,0,1,2.28-.52A5.4,5.4,0,0,1,80,98a4.63,4.63,0,0,1,1.24.51.82.82,0,0,1,.4.53.91.91,0,0,1-.51,1,.69.69,0,0,1-.64-.09A3.13,3.13,0,0,0,79,99.45a2.14,2.14,0,0,0-1.71.69,2.84,2.84,0,0,0-.61,2,3,3,0,0,0,.61,2,2.1,2.1,0,0,0,1.71.72,3.2,3.2,0,0,0,1.51-.42.68.68,0,0,1,.63-.09.76.76,0,0,1,.39.4,1,1,0,0,1,0,.6.86.86,0,0,1-.39.52,4.4,4.4,0,0,1-1.19.49A5.17,5.17,0,0,1,78.79,106.45Z"
                        fill={color ? color : '#cacaca'}
                    />
                    <path
                        d="M84.6,106.42a1,1,0,0,1-1.07-1.09V98.91a1.07,1.07,0,1,1,2.13,0v2.27h3.67V98.91a1.06,1.06,0,1,1,2.12,0v6.42a1,1,0,0,1-1.07,1.09c-.7,0-1-.36-1-1.09v-2.48H85.66v2.48A1,1,0,0,1,84.6,106.42Z"
                        fill={color ? color : '#cacaca'}
                    />
                    <path
                        d="M97,106.45a3.53,3.53,0,0,1-1.61-.35,2.81,2.81,0,0,1-1.1-.94,2.32,2.32,0,0,1-.4-1.34,2.12,2.12,0,0,1,.48-1.46,2.86,2.86,0,0,1,1.54-.77,13.8,13.8,0,0,1,2.88-.24h.59V101A1.69,1.69,0,0,0,99,99.77a1.74,1.74,0,0,0-1.26-.37,5.14,5.14,0,0,0-1.06.12,5.82,5.82,0,0,0-1.2.4.76.76,0,0,1-.68,0,.78.78,0,0,1-.37-.46,1,1,0,0,1,0-.64A.82.82,0,0,1,95,98.4a7.56,7.56,0,0,1,2.82-.6,3.8,3.8,0,0,1,2.7.84,3.45,3.45,0,0,1,.88,2.61v4.08c0,.73-.32,1.09-1,1.09s-1-.36-1-1.09V105a2.34,2.34,0,0,1-.92,1.09A2.71,2.71,0,0,1,97,106.45ZM97.4,105a1.83,1.83,0,0,0,1.39-.58,2,2,0,0,0,.55-1.46v-.37h-.58a6.91,6.91,0,0,0-2.21.24.91.91,0,0,0-.63.9,1.14,1.14,0,0,0,.4.91A1.53,1.53,0,0,0,97.4,105Z"
                        fill={color ? color : '#cacaca'}
                    />
                </g>
            </g>
        </svg>
    );
};

export default RightPlayButton;
