import React from 'react';

const LeftPauseButton = ({ onClick }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150.24 150.24"
            onClick={onClick}
        >
            <title>Button_pause</title>
            <rect
                id="Rectangle_1887-2"
                data-name="Rectangle 1887-2"
                x="7"
                y="8"
                width="136.04"
                height="134.57"
                rx="6"
                fill="#f5ffe6"
            />
            <g id="Rectangle_1888" data-name="Rectangle 1888">
                <rect
                    x="19"
                    y="19"
                    width="112"
                    height="112"
                    rx="11"
                    fill="#fff"
                />
                <path
                    d="M120,131H30a11,11,0,0,1-11-11V30A11,11,0,0,1,30,19h90a11,11,0,0,1,11,11v90A11,11,0,0,1,120,131ZM30,21a9,9,0,0,0-9,9v90a9,9,0,0,0,9,9h90a9,9,0,0,0,9-9V30a9,9,0,0,0-9-9Z"
                    fill="#87ba3b"
                />
            </g>
            <g id="лява" style={{ isolation: 'isolate' }}>
                <g style={{ isolation: 'isolate' }}>
                    <path
                        d="M63.37,106.42c-.7,0-1-.36-1-1.09V99.64H59.7v1a9.69,9.69,0,0,1-.59,3.73,3.5,3.5,0,0,1-1.91,1.94,1,1,0,0,1-.8,0,.92.92,0,0,1-.5-.45.9.9,0,0,1,.43-1.26,2.37,2.37,0,0,0,1.13-1.46,9.68,9.68,0,0,0,.28-2.62V99.16A1.05,1.05,0,0,1,58.93,98h4.32a1.05,1.05,0,0,1,1.19,1.19v6.17A1,1,0,0,1,63.37,106.42Z"
                        fill="#87ba3b"
                    />
                    <path
                        d="M73.33,106.42a.88.88,0,0,1-1-1v-2h-.89a1.45,1.45,0,0,0-.87.27,2.12,2.12,0,0,0-.62.68l-.84,1.46a1,1,0,0,1-.68.57,1.16,1.16,0,0,1-.78-.12,1.08,1.08,0,0,1-.49-.6.94.94,0,0,1,.13-.83l.53-.89a2.86,2.86,0,0,1,.49-.57A2.44,2.44,0,0,1,69,103a2.27,2.27,0,0,1-2.11-2.44,2.33,2.33,0,0,1,.82-1.89,3.62,3.62,0,0,1,2.4-.69h3.16a.94.94,0,0,1,1.07,1v6.4a.92.92,0,0,1-1,1Zm-2.8-4.4h1.82V99.36H70.53a1.87,1.87,0,0,0-1.24.35,1.19,1.19,0,0,0-.41,1,1.23,1.23,0,0,0,.41,1A1.82,1.82,0,0,0,70.53,102Z"
                        fill="#87ba3b"
                    />
                    <path
                        d="M78.13,106.28a.93.93,0,0,1-1.06-1V99a.93.93,0,0,1,1.06-1h3.43a3.71,3.71,0,0,1,2.32.57,2,2,0,0,1,.71,1.61,1.81,1.81,0,0,1-.36,1.12,2,2,0,0,1-1,.69,2.1,2.1,0,0,1,1.23.72,2.17,2.17,0,0,1-.41,3,3.79,3.79,0,0,1-2.36.63Zm.91-4.89h2.32a1.51,1.51,0,0,0,1-.28,1,1,0,0,0,.34-.79.93.93,0,0,0-.34-.78,1.58,1.58,0,0,0-1-.26H79ZM79,105h2.47c.95,0,1.43-.38,1.43-1.14s-.48-1.13-1.43-1.13H79Z"
                        fill="#87ba3b"
                    />
                    <path
                        d="M89.82,106.45a3.45,3.45,0,0,1-1.6-.35,2.77,2.77,0,0,1-1.11-.94,2.32,2.32,0,0,1-.4-1.34,2.12,2.12,0,0,1,.48-1.46,2.86,2.86,0,0,1,1.54-.77,13.8,13.8,0,0,1,2.88-.24h.59V101a1.69,1.69,0,0,0-.37-1.23,1.74,1.74,0,0,0-1.26-.37,5.14,5.14,0,0,0-1.06.12,5.82,5.82,0,0,0-1.2.4.76.76,0,0,1-.68,0,.78.78,0,0,1-.37-.46,1,1,0,0,1,0-.64.84.84,0,0,1,.52-.47,7.56,7.56,0,0,1,2.82-.6,3.8,3.8,0,0,1,2.7.84,3.45,3.45,0,0,1,.89,2.61v4.08c0,.73-.33,1.09-1,1.09s-1-.36-1-1.09V105a2.34,2.34,0,0,1-.92,1.09A2.71,2.71,0,0,1,89.82,106.45Zm.44-1.46a1.83,1.83,0,0,0,1.39-.58A2,2,0,0,0,92.2,103v-.37h-.58a6.91,6.91,0,0,0-2.21.24.9.9,0,0,0-.62.9,1.17,1.17,0,0,0,.39.91A1.53,1.53,0,0,0,90.26,105Z"
                        fill="#87ba3b"
                    />
                </g>
            </g>
            <g id="Group_1906" data-name="Group 1906">
                <path
                    id="Rectangle_1891"
                    data-name="Rectangle 1891"
                    d="M68,51.28h0a4,4,0,0,1,4,4v18a4,4,0,0,1-4,4h0a4,4,0,0,1-4-4v-18A4,4,0,0,1,68,51.28Z"
                    fill="#87ba3b"
                />
                <path
                    id="Rectangle_1892"
                    data-name="Rectangle 1892"
                    d="M83,51.28h0a4,4,0,0,1,4,4v18a4,4,0,0,1-4,4h0a4,4,0,0,1-4-4v-18A4,4,0,0,1,83,51.28Z"
                    fill="#87ba3b"
                />
            </g>
        </svg>
    );
};

export default LeftPauseButton;
