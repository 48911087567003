import React from 'react';
import { connect } from 'react-redux';
import { Col, Container } from 'reactstrap';
import {
  loadTag,
  loadTagStickers,
  selectSticker,
  showInfoModal,
} from '../../actions';
import { getActiveColor, scrollToTop } from '../../utils/commonUtils';
import MsgAlert from '../common/MsgAlert';
import NotAddedStickersList from './NotAddedStickersList';

class SelectedTagScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDescriptionBoxShown: true,
    };
  }

  componentDidMount() {
    const tagId = this.props.match.params.id;
    this.props.loadTagStickers(tagId);
    this.props.loadTag(tagId);

    // Fixing a bug that shows the tag's stickers scrolled down
    // to a certain point if another tag was opened before and
    // scrolled down and then the "back button" was used
    // to open the current tag
    scrollToTop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedTag !== this.props.selectedTag) {
      this.setState({
        borderColor: getActiveColor(this.props.selectedTag.goal, 'borderColor'),
        backgroundColor: getActiveColor(
          this.props.selectedTag.goal,
          'backgroundColor'
        ),
      });
    }
  }

  renderTagInfo() {
    const { selectedTag } = this.props;
    const { backgroundColor } = this.state;

    const msgAlertText =
      Object.keys(selectedTag).length !== 0 ? selectedTag.description : null;

    return (
      <Col
        xs={12}
        className="flexColumnCentered fix-padding-safari"
        style={{ margin: '16px 0px' }}
      >
        <MsgAlert
          text={msgAlertText}
          backgroundColor={backgroundColor}
          alertClass="infoAlert"
          isOpen={this.state.isDescriptionBoxShown}
          dismiss={() => this.dismissAlert()}
        />
      </Col>
    );
  }

  dismissAlert() {
    this.setState({
      ...this.state,
      isDescriptionBoxShown: false,
    });
  }

  render() {
    const { stickers } = this.props;

    if (!stickers || stickers.length < 1) {
      return null;
    }

    return (
      <Container className="noPadding ml-3 mr-3">
        <Col lg={12} className="centred noPadding limited-width">
          <div className="flexContainerColumn flexContainerCentered profile-form fix-padding">
            {this.renderTagInfo()}
            <NotAddedStickersList
              stickers={stickers}
              backgroundColor={this.state.backgroundColor}
              borderColor={this.state.borderColor}
            />
          </div>
        </Col>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTag: state.selectedTag,
    stickers: state.stickers.selectedStickers,
    user: state.authentication.userData,
  };
};

export default connect(mapStateToProps, {
  loadTagStickers,
  selectSticker,
  showInfoModal,
  loadTag,
})(SelectedTagScreen);
