import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RELATIVE_PAGE_PATHS, TRACKERS_SUB_TYPE } from '../../utils/constants';
import RouterLink from '../common/RouterLink';
import { MoreVert } from '@mui/icons-material';
import { useCallback } from 'react';

const InnerLink = ({ record, children, disableLink }) => {
  if (disableLink) {
    return <div className="row">{children}</div>;
  }
  const to =
    record.name === TRACKERS_SUB_TYPE.BREAST_FEEDING
      ? `${RELATIVE_PAGE_PATHS.BREASTFEEDING}/${record.id}`
      : `${RELATIVE_PAGE_PATHS.TRACKING}/${record.subTypeId}/${record.id}`;
  return (
    <RouterLink to={to}>
      <Box display={'flex'} alignItems={'center'}>
        {children}
      </Box>
    </RouterLink>
  );
};

const TrackerHistoryItem = ({
  record,
  children,
  onEdit,
  onDelete,
  disableEdit,
  hideEdit,
  disableLink,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(record.id, record.subTypeId);
    }
    handleClose();
  }, [onEdit, handleClose, record]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(record.id);
    }
    handleClose();
  }, [onDelete, handleClose, record]);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      widht="100%"
      alignItems={'center'}
    >
      <div>
        <InnerLink disableLink={disableLink} record={record}>
          {children}
        </InnerLink>
      </div>

      {!hideEdit && (
        <div>
          <IconButton aria-label="delete" onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              dense: true,
            }}
          >
            <MenuItem disabled={disableEdit} onClick={handleEdit}>
              Промени
            </MenuItem>
            <MenuItem disabled={disableEdit} onClick={handleDelete}>
              Изтрий
            </MenuItem>
          </Menu>
        </div>
      )}
    </Box>
  );
};

TrackerHistoryItem.propTypes = {
  record: PropTypes.object,
  onDelete: PropTypes.func,
};

export default TrackerHistoryItem;
