import { FC } from 'react';
import {
  SleepTrackerButtonStartDayDisabledIcon,
  SleepTrackerButtonStartDayIcon,
  SleepTrackerButtonStopDayIcon,
} from '../../common/icons';
import { StartStopButton } from './StartStopButton';

interface SleepDayButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}
export const SleepDayButton: FC<SleepDayButtonProps> = (props) => {
  return (
    <StartStopButton
      startButton={SleepTrackerButtonStartDayIcon}
      stopButton={SleepTrackerButtonStopDayIcon}
      disabledButton={SleepTrackerButtonStartDayDisabledIcon}
      {...props}
    />
  );
  // if (isActive) {
  //   return <SleepTrackerButtonStopDayIcon onClick={onClick} />;
  // }
  // if (disabled) {
  //   return <SleepTrackerButtonStartDayDisabledIcon />;
  // }
  // return <SleepTrackerButtonStartDayIcon onClick={onClick} />;
};
