import bg from 'date-fns/locale/bg';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { change, Field } from 'redux-form';
import i18n from '../../i18next';
import { store } from '../../index';
import { getColor } from '../../utils/commonUtils';
import { DEFAULT_LOCALE_CODE, EXTERNAL_URLS } from '../../utils/constants';
import { formUtils } from '../../utils/formUtils';

const textInput = ({ input, meta, type, customProps, placeholder }) => {
  const className = customProps.className
    ? customProps.className
    : 'input-container';
  const fieldPlaceholder = customProps.placeholder
    ? customProps.placeholder
    : placeholder;

  return (
    <div className={className}>
      <input
        autoComplete="none"
        className={formUtils.getFieldStyle(meta)}
        {...input}
        type={type}
        placeholder={fieldPlaceholder}
        disabled={customProps.disabled}
      />
      {formUtils.renderErrorMessage(meta)}
    </div>
  );
};

const fileInput = ({ input: { value, ...input }, meta, customProps }) => {
  const handleChange = (event) => {
    const setField = () =>
      meta.dispatch(change(meta.form, input.name, event.target.files));

    customProps.onChange(setField, event);
  };

  const inputClass = customProps.className
    ? 'uploader ' + customProps.className
    : 'uploader';

  return (
    <input
      type="file"
      className={inputClass}
      ref={customProps.ref}
      id="uploader"
      {...input}
      value={null}
      onChange={handleChange}
    />
  );
};

const textAreaInput = ({ input, meta, type, customProps, placeholder }) => {
  const rows = customProps.rows ? customProps.rows : '';
  const className = customProps.className
    ? customProps.className
    : 'input-container';
  const fieldPlaceholder = customProps.placeholder
    ? customProps.placeholder
    : placeholder;
  const style = customProps.style ? customProps.style : null;

  return (
    <div className={className} style={style}>
      <textarea
        autoComplete="none"
        rows={rows}
        className={formUtils.getFieldStyle(meta)}
        {...input}
        type={type}
        placeholder={fieldPlaceholder}
        disabled={customProps.disabled}
      />
      {formUtils.renderErrorMessage(meta)}
    </div>
  );
};

const checkbox = ({ input, label, meta, customProps }) => {
  let labelClass = 'checkbox-label';
  const className = customProps.className
    ? customProps.className
    : 'checkbox-container';
  const checkButtonClass = input.value ? 'checked' : 'not-checked';

  const renderCheckboxLabel = () => {
    switch (input.name) {
      case 'areTermsAccepted':
        return (
          <label htmlFor={input.name} className={labelClass}>
            <div className={checkButtonClass} />
            <span className="activeText">
              {label}
              <a
                href={EXTERNAL_URLS.TERMS_AND_CONDITIONS}
                target="_blank"
                rel="noreferrer"
              >
                {i18n.t('auth:fields.terms')}
              </a>
            </span>
          </label>
        );
      case 'areGDPRAccepted':
        return (
          <label htmlFor={input.name} className={labelClass}>
            <div className={checkButtonClass} />
            <span className="activeText">
              {label}
              <a href={EXTERNAL_URLS.GDPR} target="_blank" rel="noreferrer">
                {i18n.t('auth:fields.gdpr')}
              </a>
            </span>
          </label>
        );
      default:
        return (
          <label htmlFor={input.name} className={labelClass}>
            <div className={checkButtonClass} />
            <span>{label}</span>
          </label>
        );
    }
  };
  return (
    <div>
      <div className={className}>
        <input
          id={input.name}
          autoComplete="none"
          defaultChecked={input.value}
          className={formUtils.getFieldStyle(meta)}
          {...input}
          type="checkbox"
          disabled={customProps.disabled}
        />
        {renderCheckboxLabel()}
      </div>
      <div>{formUtils.renderErrorMessage(meta)}</div>
    </div>
  );
};

const radioInput = ({ input, meta, customProps }) => {
  const className = customProps.className
    ? customProps.className
    : 'radio-container';
  let labelClass = 'radio-label';

  const radioButtons = customProps.radioButtons.map((button, index) => {
    const radioId = index + 'radioButton';
    const roundButtonClass =
      input.value === button.value
        ? 'radio-button checked'
        : 'radio-button not-checked';

    return (
      <div key={radioId} className="radio">
        <label htmlFor={radioId} className={labelClass}>
          <div className={roundButtonClass}>
            <div />
          </div>
          <input
            id={radioId}
            autoComplete="none"
            className={formUtils.getFieldStyle(meta)}
            {...input}
            type="radio"
            value={button.value}
            disabled={customProps.disabled}
            checked={input.value === button.value}
          />
          <span>{button.label}</span>
        </label>
      </div>
    );
  });

  return <div className={className}>{radioButtons}</div>;
};

const dateInput = ({ input, meta, type, customProps, placeholder }) => {
  const className = customProps.className
    ? customProps.className
    : 'date-container';
  const isAndroidDevice = /(android)/i.test(navigator.userAgent);

  const handleChange = (date) => {
    meta.dispatch(change(meta.form, input.name, date));
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  registerLocale(DEFAULT_LOCALE_CODE, bg);

  /* Datepicker popperPlacement VALUES:
   * 'auto', 'auto-left', 'auto-right','bottom', 'bottom-end', 'bottom-start', 'left', 'left-end',
   * 'left-start', 'right', 'right-end', 'right-start', 'top','top-end', 'top-start'
   *
   * [Important] This configuration (popperPlacement and popperModifiers) was necessary only for iOS
   */

  return (
    <div className={className}>
      <DatePicker
        locale={DEFAULT_LOCALE_CODE}
        placeholderText={customProps.placeholder}
        onChangeRaw={handleDateChangeRaw}
        showPopperArrow={false}
        className={formUtils.getFieldStyle(meta)}
        selected={input.value}
        onChange={handleChange}
        disabled={customProps.disabled}
        dateFormat="dd.MM.yyyy"
        onKeyDown={handleDateChangeRaw}
        maxDate={customProps.filterDate ? new Date() : null}
        popperPlacement={isAndroidDevice ? '' : 'top'}
        popperModifiers={
          isAndroidDevice
            ? []
            : [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -90],
                  },
                },
              ]
        }
      />
      <div>{formUtils.renderErrorMessage(meta)}</div>
    </div>
  );
};

const dropdownTags = ({ input, meta, customProps }) => {
  let className = 'dropdown';
  if (customProps.className) {
    className += ' ' + customProps.className;
  }

  function onDropdownItemClick(tag) {
    customProps.onPress();
    meta.dispatch(change(meta.form, input.name, tag));
    customProps.onTagSelectedCallback(tag.id);
  }

  function renderDropdownCategory() {
    const goals = store.getState().goals;

    if (goals.length) {
      const dropdownItems = [
        <DropdownItem
          key="single"
          onClick={() => {
            onDropdownItemClick({
              name: i18n.t('journalScreen:all'),
              id: null,
            });
          }}
          className="mainTextColor item"
        >
          {i18n.t('journalScreen:all')}
        </DropdownItem>,
      ];

      goals.forEach((goal, index) => {
        dropdownItems.push(
          <DropdownItem
            key={`goal-${index}`}
            className="headerUppercase boldText"
            style={{
              background: getColor(goal.backgroundColor),
              color: getColor(goal.borderColor),
            }}
            header
          >
            {goal.name.toUpperCase()}
          </DropdownItem>
        );
        goal.tags.forEach((tag, tindex) => {
          dropdownItems.push(
            <DropdownItem
              key={`goal-${index}-tag-${tindex}`}
              onClick={() => onDropdownItemClick(tag)}
              className="mainTextColor item"
            >
              {tag.name}
            </DropdownItem>
          );
        });
      });

      return dropdownItems;
    }
  }

  return (
    <Dropdown
      className={className}
      isOpen={customProps.isOpen}
      direction="up"
      toggle={() => {
        // No-op function because of an error in the console
      }}
    >
      <DropdownToggle
        className="dropdownToggle mainTextColor"
        caret
        onClick={(e) => customProps.onPress && customProps.onPress(e)}
      >
        {input.value.name}
      </DropdownToggle>
      <div className="items">
        <DropdownMenu>{renderDropdownCategory()}</DropdownMenu>
      </div>
    </Dropdown>
  );
};

export const Fields = {
  commonFields: {
    email: (customProps = {}) => (
      <Field
        name="email"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.email')}
        type="text"
        component={textInput}
      />
    ),
    password: (customProps = {}) => (
      <Field
        name="password"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.password')}
        type="password"
        component={textInput}
      />
    ),
    repeatPassword: (customProps = {}) => (
      <Field
        name="repeatPassword"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.repeatPassword')}
        type="password"
        component={textInput}
      />
    ),
    parentName: (customProps = {}) => (
      <Field
        name="motherOrFatherName"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.parentName')}
        type="text"
        component={textInput}
      />
    ),
    city: (customProps = {}) => (
      <Field
        name="town"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.city')}
        type="text"
        component={textInput}
      />
    ),
    childName: (customProps = {}) => (
      <Field
        name="childName"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.childName')}
        type="text"
        component={textInput}
      />
    ),
    childBirth: (
      customProps = {
        placeholder: i18n.t('profileScreen:fields.childBirth'),
      }
    ) => (
      <Field
        name="childDateOfBirth"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.childBirth')}
        type="date"
        component={dateInput}
      />
    ),
    isChildGirl: (
      customProps = { radioButtons: RADIO_BUTTONS.IS_CHILD_GIRL }
    ) => (
      <Field
        name="isChildGirl"
        customProps={customProps}
        component={radioInput}
      />
    ),
    isChildBorn4WeeksBeforeDueDate: (customProps = {}) => (
      <Field
        name="isChildBorn4WeeksBeforeDueDate"
        customProps={customProps}
        component={checkbox}
        label={i18n.t('auth:fields.childBornMoreThan4Weeks')}
      />
    ),
    areTermsAccepted: (customProps = {}) => (
      <Field
        name="areTermsAccepted"
        customProps={customProps}
        component={checkbox}
        label={i18n.t('auth:fields.agree')}
      />
    ),
    areGDPRAccepted: (customProps = {}) => (
      <Field
        name="areGDPRAccepted"
        customProps={customProps}
        component={checkbox}
        label={i18n.t('auth:fields.agree')}
      />
    ),
    isSubscriptionActive: (customProps = {}) => (
      <Field
        name="signUpForEmailNewsletter"
        customProps={customProps}
        component={checkbox}
        label={
          <span>
            {i18n.t('auth:fields.subscription')}
            {/* <a
                            href={EXTERNAL_URLS.NEWSLETTER}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {i18n.t('auth:fields.newsletter')}
                        </a>
                        {i18n.t('auth:fields.throughEmail')} */}
          </span>
        }
      />
    ),
    oldPassword: (customProps = {}) => (
      <Field
        name="oldPassword"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.oldPassword')}
        type="password"
        component={textInput}
      />
    ),
    entered: (
      customProps = {
        placeholder: i18n.t('profileScreen:fields.childBirth'),
      }
    ) => (
      <Field
        name="entered"
        customProps={customProps}
        placeholder={i18n.t('auth:fields.childBirth')}
        type="date"
        component={dateInput}
      />
    ),
    tag: (
      customProps = {
        placeholder: i18n.t('profileScreen:fields.childBirth'),
      }
    ) => (
      <Field name="tag" customProps={customProps} component={dropdownTags} />
    ),
    uploadPhoto: (customProps = {}) => (
      <Field
        name="uploadPhoto"
        customProps={customProps}
        component={fileInput}
      />
    ),
    userTextArea: (customProps = {}) => (
      <Field
        name="userText"
        customProps={customProps}
        placeholder={i18n.t('addStickerScreen:userInputText')}
        type="text"
        component={textAreaInput}
      />
    ),
  },
  specialists: {
    name: (customProps = {}) => (
      <Field
        name="name"
        customProps={customProps}
        placeholder={i18n.t('specialistsScreen:fields.name')}
        type="text"
        component={textInput}
      />
    ),
    address: (customProps = {}) => (
      <Field
        name="notes"
        customProps={customProps}
        placeholder={i18n.t('specialistsScreen:fields.notes')}
        type="text"
        component={textAreaInput}
      />
    ),
    specialty: (customProps = {}) => (
      <Field
        name="typeOfServices"
        customProps={customProps}
        placeholder={i18n.t('specialistsScreen:fields.specialty')}
        type="text"
        component={textInput}
      />
    ),
    phone: (customProps = {}) => (
      <Field
        name="phoneNumber"
        customProps={customProps}
        placeholder={i18n.t('specialistsScreen:fields.phone')}
        type="text"
        component={textInput}
      />
    ),
    isFamilyPhysician: (customProps = {}) => (
      <Field
        name="isFamilyPhysician"
        customProps={customProps}
        component={checkbox}
        label={i18n.t('specialistsScreen:fields.GP')}
      />
    ),
  },
  paidFunctionality: {
    username: (customProps = {}) => (
      <Field
        name="username"
        customProps={customProps}
        placeholder={i18n.t('paidFunctionalityScreen:fields.username')}
        type="text"
        component={textInput}
      />
    ),
    userEmails: (customProps = {}) => (
      <Field
        name="userEmails"
        customProps={customProps}
        placeholder={i18n.t('paidFunctionalityScreen:fields.emails')}
        type="text"
        component={textAreaInput}
      />
    ),
  },
};

const RADIO_BUTTONS = {
  IS_CHILD_GIRL: [
    { label: i18n.t('auth:fields.boy'), value: '' },
    { label: i18n.t('auth:fields.girl'), value: '1' },
  ],
};
