import * as i18n from 'i18next';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container } from 'reactstrap';
import { reduxForm } from 'redux-form';
import {
    deleteUserProfiles, getPaidFunctionalityCredentials, lockPaidFunctionality,
    showDeleteModal, unlockPaidFunctionality
} from '../actions';
import { FORMS } from '../utils/constants';
import { formUtils } from '../utils/formUtils';
import { Fields } from './common/Fields';
import LocalNavigationComponent from './LocalNavigationComponent';

class PaidFunctionalityScreen extends React.Component {
    renderFields = () => {
        const { username, password } = this.props.paidFunctionalityCredentials;
        if (username && password) {
            return (
                <Fragment>
                    {Fields.paidFunctionality.userEmails({
                        className: 'input-container user-emails',
                    })}
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    {Fields.paidFunctionality.username()}
                    {Fields.commonFields.password()}
                </Fragment>
            );
        }
    };

    renderButtons = () => {
        const { username, password } = this.props.paidFunctionalityCredentials;
        if (username && password) {
            return (
                <Fragment>
                    <Button
                        className="greenBtn header next uppercase"
                        onClick={this.props.handleSubmit(this.onUnlock)}
                    >
                        {i18n.t('paidFunctionalityScreen:buttons.unlock')}
                    </Button>
                    <Button
                        className="header next uppercase redBtn"
                        onClick={this.props.handleSubmit(this.onLock)}
                    >
                        {i18n.t('paidFunctionalityScreen:buttons.lock')}
                    </Button>
                    <Button
                        className="header next uppercase redBtn"
                        onClick={this.props.handleSubmit(this.onDelete)}
                    >
                        {i18n.t(
                            'paidFunctionalityScreen:buttons.deleteProfiles'
                        )}
                    </Button>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Button
                        className="greenBtn header next uppercase"
                        onClick={this.props.handleSubmit(this.onLogin)}
                    >
                        {i18n.t('buttons:next')}
                    </Button>
                </Fragment>
            );
        }
    };

    onLogin = (formValues) => {
        this.props.getPaidFunctionalityCredentials(formValues);
    };

    onLock = (formValues) => {
        let userEmails = formValues.userEmails.split(',');
        const credentials = this.props.paidFunctionalityCredentials;
        userEmails = userEmails.map((email) => email.trim());
        this.props.lockPaidFunctionality({ credentials, userEmails });
    };

    onUnlock = (formValues) => {
        let userEmails = formValues.userEmails.split(',');
        const credentials = this.props.paidFunctionalityCredentials;
        userEmails = userEmails.map((email) => email.trim());
        this.props.unlockPaidFunctionality({ credentials, userEmails });
    };

    onDelete = (formValues) => {
        let userEmails = formValues.userEmails.split(',');
        const credentials = this.props.paidFunctionalityCredentials;
        userEmails = userEmails.map((email) => email.trim());
        this.props.showDeleteModal({
            message: i18n.t('paidFunctionalityScreen:confirmDelete'),
            onDelete: () => {
                this.props.deleteUserProfiles({ credentials, userEmails });
            },
        });
    };

    render() {
        return (
            <Container className="noPadding">
                <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="centred noPadding"
                >
                    <LocalNavigationComponent
                        header={i18n.t('paidFunctionalityScreen:title')}
                    />
                    <form className="flexContainerColumn flexContainerCentered fix-padding">
                        <div
                            className="flexContainerColumn flexContainerCentered"
                            style={{ margin: '24px 0px' }}
                        >
                            {this.renderFields()}
                        </div>

                        <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={8}
                            className="flexContainerColumn centred"
                            style={{ margin: '24px 0px 12px 0px' }}
                        >
                            {this.renderButtons()}
                        </Col>
                    </form>
                </Col>
            </Container>
        );
    }
}

const adminForm = reduxForm({
    form: FORMS.ADMIN_FORM,
    validate: formUtils.validatePaidFunctionality,
})(PaidFunctionalityScreen);

const mapStateToProps = (state) => {
    return { paidFunctionalityCredentials: state.paidFunctionalityCredentials };
};

export default connect(mapStateToProps, {
    getPaidFunctionalityCredentials,
    unlockPaidFunctionality,
    lockPaidFunctionality,
    showDeleteModal,
    deleteUserProfiles,
})(adminForm);
